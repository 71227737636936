// Buttons
@use "../variables/bs5-grid-breakpoints" as *;
@use "../variables/colors";
@use "../variables/typography";
@use "../helpers/mixins" as *;
@use "../helpers/helpers" as *;
@use "sass:map";

.btn {
  @include fontsize(14, 18);

  text-transform: uppercase;
  transition: background 0.25s linear;
  text-align: center;
  padding: 13px 26px;
  // max-width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bold;
  white-space: normal;

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    @include fontsize(16, 20);

    padding: 14px 25px;
  }

  @media (min-width: map.get($grid-breakpoints, "xl")) {
    max-width: 100%;
  }

  &.btn-primary {
    color: var(--white);
    background: var(--primary-blue);
    border-color: var(--primary-blue);
  }

  &.btn-default,
  &.btn-primary {
    &:hover,
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      color: darken(#6787b7, 15%);
      background-color: darken(#fff, 15%);
    }
  }

  .btn-secondary {
    color: var(--black);
    background-color: var(--esc-primary);
  }

  &.btn-reverse {
    border: var(--accent-blue) 2px solid;
    color: var(--accent-blue);
    background-color: var(--white);
  }

  &:hover,
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }

  &.btn-cta {
    border-radius: 10px;
    width: 100%;
    max-width: 320px;
    text-align: left;
    line-height: 28px;
    margin-bottom: 10px;
    color: var(--white);
    background: var(--primary-blue);

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      padding: 6px 10px;
    }

    &:active,
    &:hover {
      outline: none;
      box-shadow: none;
      text-decoration: underline;
      background: var(--primary-blue);
    }

    &:focus {
      outline: dotted;
    }

    &::after {
      content: "»";
      float: right;
      font-weight: 700;
      font-size: 26px;
      line-height: 26px;
    }
  }
}

.right-option {
  margin-top: 20px;

  .btn-list.list-unstyled li {
    width: 100%;

    a.btn-stacked {
      width: 100px;
    }
  }
}

/* Adding option for stacked "right hand manu" style buttons */
.btn-stacked {
  margin-bottom: 5px;
  width: 100%;
}

.btn.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;

  &.btn-lg {
    width: 50px;
    height: 50px;
    padding: 8px 8px;
    border-radius: 25px;
    font-size: 24px;
    line-height: 1.38;
  }

  &.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
  }
}

.btn-left {
  position: absolute;
  top: 50%;
  left: -50px;

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    left: -75px;
  }
}

.btn-right {
  position: absolute;
  top: 50%;
  right: -50px;

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    right: -75px;
  }
}

/* Orange button-style link */

.carousel {
  a.btn.btn-secondary {
    color: var(--black);
    background-color: var(--esc-primary);
    padding: 3px 12px;
    width: 250px;
    text-transform: none;
  }
}
