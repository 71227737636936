// accordions 2021

@use "../variables/bs5-grid-breakpoints" as *;
@use "../variables/colors";
@use "../variables/typography";
@use "../helpers/mixins" as *;
@use "../helpers/helpers" as *;
@use "sass:map";

.accordion-flush {
  background: none;
  padding: 0 0 1px;
  border: none;
}

.accordion-item {
  border: 1px solid var(--white);
}

.accordion-header {
  margin-top: 0 !important;
}

.accordion-button {
  color: var(--white);
  background-color: var(--primary-blue);

  &::before {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    content: "▲";
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.3s ease-in-out;
    transform-origin: 0.5rem;
    color: var(--esc-primary);
  }

  &:not(.collapsed) {
    color: var(--white);
    background-color: var(--primary-blue);

    &::before {
      transform: rotate(-180deg);
      transform-origin: 0.5rem;
      color: var(--secondary-blue);
    }

    &::after {
      background-image: none;
      transform: none;
    }
  }

  &::after {
    background-image: none;
    transform: none;
  }
}

.accordion-collapse.collapse.show,
.accordion-collapse.collapsing {
  border: 1px solid var(--esc-primary);
  background-color: #ff980012;
}
