#cookieModal {
  z-index: 9999999;
}

#cookieModal .modal-dialog {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0;
}
