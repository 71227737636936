/* Tables */

@use "../variables/bs5-grid-breakpoints" as *;
@use "../variables/colors";
@use "../variables/typography";
@use "../helpers/mixins" as *;
@use "../helpers/helpers" as *;
@use "sass:map";

th {
  a {
    color: var(--white);
    font-weight: 700;
    text-decoration: underline;
  }

  a::after {
    content: " \00BB";
  }

  a:hover,
  a:active,
  a:focus {
    color: var(--white);
  }
}

thead th,
thead td {
  background: var(--tertiary-blue) !important;
  color: var(--white);
}

.table-borderless td,
.table-borderless th {
  border: none !important;
}

/* Override Bootstrap default of bottom */
.table > thead > tr > th {
  vertical-align: middle;
}

.table-hover > tbody > tr:nth-of-type(odd) {
  @media (max-width: map.get($grid-breakpoints, "lg")) {
    background-color: var(--gray-bg);
  }
}

.table-fixed {
  table-layout: auto;

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    table-layout: fixed;
  }
}
