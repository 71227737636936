.panel-heading {
  border-bottom: 3px solid #d76f21 !important;
  margin-bottom: 0.75em !important;
  background: #fff !important;
}

.panel {
  margin-bottom: 2em;
}

h2.panel-title {
  font-size: 32px;
}

@media (min-width: 641px) {
  h3.panel-title {
    font-size: 26px;
  }
}

@media (min-width: 941px) {
  h3.panel-title {
    font-size: 28px;
  }
}
