@import '../variables/bootstrap-custom';
@import '../helpers/mixins';
@import '../lib/include-media';

/* ==========================

   Landing Page Customizations

   Information Sessions
   RFI

   ========================== */

.ct-InformationSessions {
  .shield img {
    margin: 20px 0 20px 0;

    @include media('>=tablet') {
      margin: 120px 0 60px 0;
    }
  }

  .is-button {
    margin-bottom: 40px;
  }

  .is-button-2 {
    padding: 20px 0;

    @include media('>=tablet') {
      padding-top: 60px;
    }
  }

  .is-cta-block {
    background-color: $clr18;
    padding: 30px 0 40px 0;
  }

  .moment {
    background-color: $esc-primary;
    max-height: 500px;

    @include media('>=tablet') {
      max-height: 200px;
    }

    img {
      padding: 25px 0 0 0;

      @include media('>=tablet') {
        padding: 25px 15px 0 25px;
      }
    }

    .moment-quote {
      padding-top: 15px;
      padding-left: 0;
      padding-right: 20px;
      font-family: serif;
      font-weight: bold;
      font-style: italic;
      line-height: 1.2em;

      @include media('>=tablet') {
        padding-top: 25px;
        padding-left: 40px;
      }
    }
  }

  /*
  .moment-img img{
    padding: 25px 0 0 0;
  }
  */

  .nys-icon {
    padding-top: 40px;
    max-width: 175px;

    img {
      margin-top: 0;
      margin-left: 60px;

      @include media('>=tablet') {
        margin-top: initial;
        margin-left: initial;
      }
    }
  }

  .list-right {
    margin-top: -10px;

    @include media('>=tablet') {
      margin-top: initial;
    }
  }

  /* Adjust default bootstrap setting for greater line-height */
  input[type="radio"] {
    margin-top: 8px;
  }
}

/* ==================== */

.learn-more {
  .shield {
    padding: 20px 0 20px 0;

    @include media('>=tablet') {
      padding: 60px 0 60px 0;
    }
  }

  .schools {
    background-color: $esc-primary;
  }

  .school-blocks {
    max-width: 1200px;
  }

  .shadow-box-outer {
    padding: 20px 40px;
    max-width: 420px;
  }

  .shadow-box-inner {
    min-height: 300px;
    font-size: 1em;
    background-color: $white;
    padding: 10px;
    box-shadow: 0 1px 8px 2px $clr29;
    border-radius: 8px;

    @include media('>=tablet') {
      min-height: 400px;
      font-size: 0.85em;
    }
  }

  .shadow-box-inner-2 {
    min-height: 300px;
    font-size: 1em;
    background-color: $white;
    padding: 10px;
    box-shadow: 0 1px 8px 2px $clr29;
    border-radius: 8px;

    @include media('>=tablet') {
      min-height: 320px;
      font-size: 0.85em;
    }
  }
}
