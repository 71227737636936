@import "../variables/colors";
@import "../variables/bootstrap-custom";

/* BELOW legacy contextual stuff to phase out or move to a content type or otherwise unique css */

/* follow block
---------------------------------------------------------------------------------- */
.follow-block {
  margin-bottom: 20px;
}

.follow-block a {
  margin: 4px;
  border-bottom: 0;
}

/* news classes
------------------------------------------------------------------------ */
.topnews h1,
.topnews h2 {
  padding-bottom: 0;
}

.topnews h2 {
  padding-top: 0;
  font-size: 140%;
}

.topnews.feature h2 {
  font-size: 160%;
}

.topnews li {
  margin-top: 0.8rem;
  padding-top: 0.8rem;
}

/* misc classes
------------------------------------------------------------------------ */

/* don't use - NEED TO PHASE OUT */
.right {
  float: right;
}

.left {
  float: left;
}

/* end don't use */
.highlight {
  color: $clr7;
  font-weight: 700;
}

.float-right,
.float-left {
  float: none;
  margin: 0;
}

@media (min-width: 641px) {
  .float-right {
    float: right;
    margin-left: 10px;
    margin-right: 0;
  }

  .float-left {
    float: left;
    margin-right: 10px;
    margin-left: 0;
  }
}

.clear-right {
  clear: right;
}

.clear-left {
  clear: left;
}

.clear-both {
  clear: both;
}

.pad {
  padding: 10px;
}

.pad-top {
  padding-top: 1.5em;
}

.pad-top-more {
  padding-top: 2em;
}

.pad-bottom {
  padding-bottom: 1.5em;
}

.pad-h {
  padding-left: 10px;
  padding-right: 10px;
}

.pad-left {
  padding-left: 30px;
}

.pad-right {
  padding-right: 30px;
}

.notice {
  color: $brand-danger;
}

.smaller {
  font-size: 0.8em;
  line-height: 1.3;
}

.black {
  color: $gray-base;
}

.border-top {
  border-top: 1px solid $clr21;
  padding-top: 3px;
  margin-top: 3px;
}

.border-bottom {
  border-top: 1px solid $clr13;
  padding-top: 6px;
  margin-top: 6px;
}

.nobr {
  white-space: nowrap;
}

/* classes not for the editor */
.valign {
  vertical-align: middle;
}

.valign-top {
  vertical-align: top;
}

.valign-bottom {
  vertical-align: bottom;
}

.display-block {
  display: block;
}

.boxover {
  height: 300px;
  overflow-y: scroll;
}

.show {
  display: block;
}

.img-text {
  margin-top: -3px;
  top: 4px;
  position: relative;
}

.hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.hide {
  left: -9999em;
  position: absolute;
}

.show-normal,
.show-normal .hide {
  left: 0;
  position: relative;
}

/*    Clear Floated Elements, from http://sonspring.com/journal/clearing-floats */
.clear {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}

/* http://perishablepress.com/press/2009/12/06/new-clearfix-hack */

/* new clearfix */
.clearfix::after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

/* -------------------------------------------------------------------------------
   some styles for specific content types or templates
---------------------------------------------------------------------------------- */
p.intro {
  font-size: 1.3em;
  color: $clr7;
}

.topnews {
  border-bottom: 1px solid $clr9;
  margin: 30px 0 15px;
  overflow: auto;
  padding: 0;
  position: relative;
  width: 100%;
  clear: left;
}

div.topnews hr {
  display: block;
  clear: left;
  visibility: hidden;
}

/* keep for float clearing */
.news h4,
.news h3 {
  margin-top: 10px;
  padding: 0;
}

.date {
  font-size: 0.8em;
  // font-style: italic;
  color: $gray;
  margin: 0.4rem 0;
}

.topnews h3 {
  font-size: 130%;
  margin-top: 0;
}

.topnews h2 a:link::after,
.topnews h3 a:link::after {
  content: "";
}

/* ``````````````````````images, image containers, captions`````````````````````` */

img.right,
img.float-right {
  margin-left: 20px;
}

img.left,
img.float-left {
  margin-right: 20px;
}

.image-area.clear {
  margin: 0;
  padding: 0;
}

.image-area {
  margin: 0 10px 10px;
}

.image-area .right,
.image-area.right,
.image-area .float-right,
.image-area.float-right {
  margin-right: 0;
}

.image-area .left,
.image-area.left,
.image-area .float-left,
.image-area.float-left {
  margin-left: 0;
}

.image-area p {
  color: $clr7;
  font-size: 0.8em;
  margin: 0.4rem 0;
}

/* only used on SD page www.esc.edu/service-desk */

.media {
  // Proper spacing between instances of .media
  margin-top: 15px;

  &:first-child {
    margin-top: 0;
  }
}

.media,
.media-body {
  /* *zoom: 1; // IE 5.5-7 Only */
  overflow: hidden;
}

.media-body {
  width: 10000px;
}

.media-object {
  display: block;

  // Fix collapse in webkit from max-width: 100% and display: table-cell.
  &.img-thumbnail {
    max-width: none;
  }
}

.media-right,
.media > .pull-right {
  padding-left: 10px;
}

.media-left,
.media > .pull-left {
  padding-right: 10px;
}

.media-left,
.media-right,
.media-body {
  color: $clr7;
  display: table-cell;
  vertical-align: middle;
}

.media-middle {
  vertical-align: middle;
}

.media-bottom {
  vertical-align: bottom;
}

// Reset margins on headings for tighter default spacing
.media-heading {
  margin-top: 0;
  margin-bottom: 5px;
}

// Media list variation
//
// Undo default ul/ol styles
.media-list {
  padding-left: 0;
  list-style: none;
}
