/* Upgrade Utilities */

@use "../variables/bs5-grid-breakpoints" as *;
@use "../variables/colors";
@use "../variables/typography";
@use "../helpers/mixins" as *;
@use "../helpers/helpers" as *;
@use "sass:map";

// This is a temporary file to use during the upgrade process.
// When we remove bs3 code from the site and upgrade to bs5,
// we can remove part (eventually all) of this file.

@media (max-width: map.get($grid-breakpoints, "xs")) {
  .visible-xs-block {
    display: block !important;
  }
}

@media (min-width: map.get($grid-breakpoints, "sm")) {
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-sm-offset-2 {
    margin-left: 16.66667%;
  }
}

@media (min-width: map.get($grid-breakpoints, "md")) {
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-md-offset-2 {
    margin-left: 16.66667%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-0 {
    margin-left: 0;
  }
}

@media (min-width: map.get($grid-breakpoints, "lg")) {
  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }
}

.visible-lg,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block,
.visible-md,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-sm,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-xs,
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block {
  display: none !important;
}

.form-group {
  margin-bottom: 1rem;
}

.form-inline .form-control {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

label {
  margin-bottom: 0.5rem;
}

.btn-block {
  display: block;
}
