// @import '../helpers/mixins';
// @import '../lib/include-media';
@use "../variables/bs5-grid-breakpoints" as *;
@use "../helpers/mixins"; // include mixins.something? whynnot use as star ?
@use "../variables/colors";
@use "sass:map";

/* Toggle Expandable Content */

.expand_wrapper {
  background: none;
  padding: 0 0 1px;
  // border: 4px solid #00438c;
}

h3.toggle.more {
  background: none;
  font-size: 100%;
  padding-left: 0;
}

div.toggle {
  padding: 10px;
}

div.toggle.more {
  padding: 0;
}

.ui-button {
  padding: 1px 6px;
}

button::-moz-focus-inner {
  padding: 0;
}

/* fix the weird Firefox button padding: */

/* New Toggle look and colors */
h3.toggle {
  font-size: 100%;
  line-height: 1.4;
  margin: 0;
  padding: 10px 10px 9px;
  position: relative;
  background: #1d428a;
  border: 0;
}

h3.toggle a {
  color: #fff;
  background: none;
  padding-left: 5px;
  font-weight: 600;
  display: block;
  font-size: 130%;
}

h3.toggle a:hover {
  color: #fed84b;
  background: none;
}

h3.toggle > a::before {
  content: "-";
  padding-right: 11px;
  font-size: 22px;
}

h3.toggle > a.collapsed::before {
  content: "+";
  padding-right: 6px;
  font-size: 22px;
}

h3.toggle > a:hover,
h3.toggle > a:active {
  text-decoration: none;
  outline: 0 !important;
}

h3.toggle > a:focus {
  text-decoration: none;
}

.toggle.collapse.in {
  background-color: #fef9ed;
  border: 1px solid #ff9800;
  margin-bottom: 0;
}

.toggle h4 {
  margin-top: 3rem;
}
