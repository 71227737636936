// Footer

@use "../variables/bs5-grid-breakpoints" as *;
@use "../variables/colors";
@use "../variables/typography";
@use "../helpers/mixins" as *;
@use "../helpers/helpers" as *;
@use "sass:map";

footer {
  .circle-base {
    border-radius: 50%;
    width: 135px;
    height: 135px;
    background-color: var(--primary-blue);
    margin-right: auto;
    margin-left: auto;
    transform: translateY(67px);
    margin-top: -67px;
  }

  .container-fluid {
    color: var(--white);
    background: var(--primary-blue);
    font-family: var(--font-family-sans-serif);
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;

    .row {
      padding-top: 15px;
      padding-bottom: 15px;

      &:first-of-type {
        padding-top: 170px;
      }
    }
  }

  a {
    color: var(--white);
    font-family: var(--font-family-sans-serif);
    font-size: 16px;
    font-weight: 300;

    &:hover,
    &:active {
      color: var(--white);
      text-decoration: underline;
      text-decoration-color: var(--esc-primary);
      // background: rgb(29, 66, 138);
    }
  }

  .os-torch-icon {
    display: block;
    transform: translate(-83px, 24px);
    text-align: left;
  }

  .footer-social-icon {
    padding-right: 15px;
  }

  .footer-2column {
    @media (min-width: map.get($grid-breakpoints, "md")) {
      column-count: 2;
      float: none;
      max-width: 400px;
      margin: 0 auto;
    }
  }

  .footer-spacer {
    white-space: pre-wrap;
  }
}
