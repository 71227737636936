/* Kaltura Video iFrame */

/* clear out the font based icon */
.largePlayBtn.icon-play::before {
  content: "";
}

/* replace play button: */
.largePlayBtn {
  background-color: transparent !important;
}

.largePlayBtn:hover {
  background-color: transparent !important;
  opacity: 0.6;
}

.mwPlayerContainer .largePlayBtn,
.mwPlayerContainer .largePlayBtn:hover {
  background-image: url('../openhouse/icon-play.png');
  background-size: 93px 100px;
  width: 93px;
  height: 100px;
  padding: 0;
  margin: -50px;
}
