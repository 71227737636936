// slideshow 2021

@use "../variables/bs5-grid-breakpoints" as *;
@use "../variables/colors";
@use "../variables/typography";
@use "../helpers/mixins" as *;
@use "../helpers/helpers" as *;
@use "sass:map";

.slideshow {
  position: relative;

  /* min-height: 364px; */
  margin: 59px -15px 0;

  .slideset {
    position: relative;
    z-index: 2;
    overflow: visible !important;
  }

  &::after {
    content: "";
    display: block;
    font-size: 0;
    text-indent: -9999px;
    width: 2px;
    height: 100%;
    background: var(--gray-bg);
    position: absolute;
    top: 0;
    left: 50%;
    margin: 0 0 0 -1px;
    z-index: 1;
  }

  @media (min-width: map.get($grid-breakpoints, "md")) {
    min-height: 595px;
    margin-top: 0;
  }

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    margin: 0;
    min-height: 650px;
  }

  &.active {
    .icon-play {
      opacity: 0 !important;
    }

    .head,
    .slide.active .blockquote {
      @media (min-width: map.get($grid-breakpoints, "md")) {
        opacity: 0 !important;
      }
    }

    .slide.active .blockquote {
      @media (min-width: map.get($grid-breakpoints, "md")) {
        transition: all 0.2s;
        transform: translateX(30px);
      }
    }

    video,
    iframe {
      z-index: 2;
    }

    .icon-stop {
      opacity: 1;
    }

    &.visibility-hidden {
      .icon-play {
        visibility: hidden;
      }

      .head,
      .blockquote {
        @media (min-width: map.get($grid-breakpoints, "md")) {
          visibility: hidden;
        }
      }

      .slide.active .blockquote {
        transition: all 0.2s;

        @media (min-width: map.get($grid-breakpoints, "lg")) {
          transform: translateX(45px);
        }
      }
    }
  }

  .head {
    background: var(--esc-primary);
    position: absolute;
    left: 0;
    bottom: 100%;
    right: 0;
    z-index: 3;
    padding: 7px 15px;
    text-align: center;
    transition: opacity 0.2s;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      padding: 0;
      background: rgba(255, 255, 255, 0.75);
      position: absolute;
      left: 0;
      top: 0;
      bottom: auto;
      right: 0;
    }
  }

  .btn-prev,
  .btn-next {
    position: absolute;
    display: block;

    @include hide-text();

    top: 50%;
    width: 40px;
    height: 40px;
    transform: translateY(-50%);
    transition: opacity 0.25s linear;

    &:hover {
      opacity: 0.7;
    }

    &::after {
      content: "";
    }
  }

  .btn-prev {
    &::after {
      @include triangle(14px, --white, left);

      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -7px;
      margin-left: 0;
    }

    left: 0;
  }

  .btn-next {
    right: 0;

    &::after {
      @include triangle(14px, --white, right);

      position: absolute;
      top: 50%;
      right: 50%;
      margin-top: -7px;
      margin-right: 0;
    }
  }

  .counter {
    @include fontsize(12, 16);

    display: block;
    margin: 0 0 5px;
    color: var(--white);
  }

  .switcher {
    @include font(14, 18, 700);

    margin: 0;

    @include vertical();

    @media (min-width: map.get($grid-breakpoints, "md")) {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
      justify-content: center;
    }

    li {
      @include vertical();

      @media (min-width: map.get($grid-breakpoints, "md")) {
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        width: 19%;
        display: block !important;
        opacity: 1 !important;
      }

      @media (min-width: map.get($grid-breakpoints, "lg")) {
        width: 20%;
      }

      &.active {
        a {
          @media (min-width: map.get($grid-breakpoints, "md")) {
            background: var(--accent-blue);
            color: var(--white);

            &::before {
              height: 5px;
            }
          }
        }
      }
    }

    a {
      color: var(--white);
      font-weight: 700;
      display: flex;
      width: 100%;
      text-align: center;
      transition: all 0.25s linear;
      vertical-align: middle;
      height: 100%;
      justify-content: center;
      align-items: center;

      @include pseudo-element(before, auto, 0, 0, 0);

      @media (min-width: map.get($grid-breakpoints, "md")) {
        color: var(--black);
        padding: 16px 12px;
      }

      &::before {
        transition: height 0.25s linear;
        height: 0;
        background: #f2a90040;
      }

      &:hover {
        @media (min-width: map.get($grid-breakpoints, "md")) {
          background: var(--accent-blue);
          color: var(--white);
        }
      }
    }
  }

  .slide {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    backface-visibility: hidden;
    transform: translateZ(0);

    > div {
      position: relative;
      display: block;
      color: rgba(255, 255, 255, 0.8);
      transition: color 0.25s linear;
      cursor: pointer;

      &:hover {
        color: var(--white);
      }
    }
  }

  .icon-play,
  .icon-stop {
    @include vertical();

    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    transform: translate(-50%, -50%);
    margin: -14px 0 0 2px;

    @include size(35px);

    font-size: 35px;
    transition: opacity 0.2s;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      @include size(55px);

      font-size: 55px;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      @include size(65px);

      font-size: 65px;
    }
  }

  @include common-slide-video();

  .blockquote {
    background: var(--accent-blue);

    @include font(16, 25, 300);

    position: relative;
    z-index: 3;
    padding: 18px 26px 30px;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      padding: 25px 30px 32px;

      @include fontsize(28, 38);

      max-width: 610px;
      margin: -52px auto 0;

      /* transition: opacity 0.6s ease-in 0.4s, transform 0.6s ease-in 0.4s;
      opacity: 0;
      transform: translateX(30px); */
      transform: translateX(15px);
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      float: right;
      max-width: none;
      margin: -111px -10px 0;
      width: 63%;
      padding: 25px 30px 32px 46px;
    }

    @media (min-width: map.get($grid-breakpoints, "xxl")) {
      margin: -111px -33px 0;
    }

    @media (max-width: map.get($grid-breakpoints, "md")) {
      margin-top: -1px;
    }

    q {
      @media (min-width: map.get($grid-breakpoints, "md")) {
        margin: 0 0 20px;
      }
    }

    cite {
      font-weight: 300;

      @media (min-width: map.get($grid-breakpoints, "md")) {
        @include fontsize(20, 24);
      }

      &::before {
        margin: 0 6px 0 -5px;

        @media (min-width: map.get($grid-breakpoints, "md")) {
          margin: 0 6px 0 -25px;
          width: 20px;
        }
      }
    }
  }
}
