// Base
@use "../variables/bs5-grid-breakpoints" as *;
@use "../variables/colors";
@use "../variables/typography";
@use "../helpers/mixins" as *;
@use "../helpers/helpers" as *;
@use "sass:map";

:root {
  --bs-scroll-height: 85vh;
  --bs-gutter-x: 0.75rem;
  --bs-font-sans-serif: transat-text, open-sans, sans-serif;
}

a {
  font-weight: 700;
  text-decoration: none;
  color: var(--link-color);
}

html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

header {
  flex: 0 0 auto;
  background: var(--primary-blue);
  border-bottom: 2px solid var(--esc-primary);
  position: fixed;
  z-index: 999;

  @media (min-width: map.get($grid-breakpoints, "xl")) {
    position: static;
  }
}

header.container-fluid {
  padding-left: 0;
  padding-right: 0;

  @media (min-width: map.get($grid-breakpoints, "xl")) {
    padding-left: var(--bs-gutter-x, 0.75rem);
    padding-right: var(--bs-gutter-x, 0.75rem);
  }
}

main {
  flex: 1 0 auto;

  header {
    background: none;
    border-bottom: 0;
  }
}

footer {
  flex-shrink: 0;
  text-align: center;
  margin-top: -80px;
}

h1 {
  // text-transform: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  font-weight: bold;

  a::after {
    content: "\F101";
    font-family: "fontawesome", sans-serif;
    font-size: inherit;
    margin: 0 0 0 5px;
    display: inline-block;
    vertical-align: top;
  }

  a:not([href]):not([class]),
  a:not([href]):not([class]):hover {
    display: none;
  }
}

h2 {
  // font-size: 2.5em;

  &.h2 {
    font-family: --headings-font-family-h1, sans-serif;
    text-transform: none;
    font-weight: 700;
    margin-top: 0;
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0.7em;
  color: inherit;
}

/* .h1 {
  filter
} */

blockquote {
  border: none;
}

.intro-video-holder {
  position: relative;

  /*
  z-index: -1;
  */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /*
  padding: 0 10% 0 5%;
  */

  .intro-video {
    position: relative;
    z-index: 1;
    opacity: 0;
    transition: opacity 1s;

    &.on {
      opacity: 1;
    }
  }
}

.poster-holder {
  max-height: inherit;
  height: auto;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: center/cover no-repeat;
}

.btn-pla {
  margin-top: 7em;
  margin-bottom: 3em;
}

.cta-header .btn.btn-primary {
  @media (min-width: map.get($grid-breakpoints, "lg")) {
    line-height: 15px;
    padding: 10px 22px;
  }
}

/* main informative part styles */
#main {
  @extend %clearfix;

  padding-top: 57px;

  &.keypage {
    padding-top: 57px;
    padding-bottom: 90px;

    .banner {
      // margin-top: -20px;
    }

    /* .questions-block {
      margin-bottom: -78px;
    } */
  }

  @media (min-width: map.get($grid-breakpoints, "xl")) {
    padding-top: 0;

    &.keypage {
      padding-top: 0;
    }
  }

  /* padding-top: 64px; delete all this jen 2021

  @media (min-width: map.get($grid-breakpoints, "md")) {
    padding-top: 176px;
  }

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    padding-top: 160px;
  }

  @media (max-width: map.get($grid-breakpoints, "lg")) {
    padding-top: 66px;
  }

  @media (max-width: map.get($grid-breakpoints, "md")) {
    padding-top: 60px;
  } */
}

body.menu-active {
  overflow: hidden;
}

.open-close {
  .slide {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: var(--black);
    padding: 7px 19px;
    max-height: 300px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

/* banner styles */
.banner {
  position: relative;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  justify-content: center;
  color: var(--esc-primary);

  @include fontsize(16, 22);

  background: var(--white);
  z-index: 5;
  overflow: hidden;

  @media (min-width: map.get($grid-breakpoints, "md")) {
    @include fontsize(20, 27);
  }

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    @include font(24, 33, 300);
  }

  .bg-stretch {
    z-index: -1;
    max-height: 320px;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      max-height: inherit;
    }
  }

  &.in-viewport {
    h1,
    .text {
      opacity: 1;
      transform: none;
      color: var(--white);
    }

    h1 {
      text-transform: uppercase;
      text-shadow: #000000b4 0 3px 4px;
      font-family: var(--font-family-sans-serif-custom);
      font-size: 50px;
    }

    .text {
      @media (max-width: map.get($grid-breakpoints, "md")) {
        // margin-bottom: 60px;
      }
    }
  }

  &.intro-block {
    margin-bottom: 0;
    background: var(--white);
    color: var(--black);
    overflow: visible;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      // margin-bottom: 0.75em; 2021 delete
      color: var(--white);
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      // margin-bottom: 1em; 2021 delete

      .align {
        padding: inherit;
      }
    }

    .holder {
      align-items: flex-start;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -ms-flex-align: center;
      -webkit-align-items: center;
      padding: 20px 0; // !important;

      @media (min-width: map.get($grid-breakpoints, "md")) {
        align-items: center;
        min-height: calc(408px - 84px);
      }

      @media (min-width: map.get($grid-breakpoints, "lg")) {
        // padding: 120px 0 111px; 2021
        // max-height: 200px;
        min-height: 300px;
      }

      @media (max-width: map.get($grid-breakpoints, "md")) {
        // padding: 45px 0 10px; 2021
        min-height: 1px;
      }
    }

    .bg-stretch {
      @media (max-width: map.get($grid-breakpoints, "md")) {
        // max-height: none;
      }
    }

    .align {
      @media (min-width: map.get($grid-breakpoints, "lg")) {
        padding: 0;
      }
    }

    h1 {
      margin: 0 0 12px;
      color: var(--white);

      @media (max-width: map.get($grid-breakpoints, "md")) {
        font-size: 28px;
        margin: 0 0 10px;

        @include fontsize(36, 42);
      }

      @media (min-width: map.get($grid-breakpoints, "lg")) {
        margin: 0 0 11px;
      }
    }
  }

  .text {
    display: block;

    /* transition: opacity 0.5s ease-out 0.8s, transform 0.5s ease-out 0.8s;
    transform: translateY(-15px);
    opacity: 0; */

    br {
      @media (max-width: map.get($grid-breakpoints, "md")) {
        display: none;
      }
    }
  }

  .holder {
    position: relative;
    z-index: 1;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 130px 0 60px;
    min-height: 50px;
    display: flex;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      padding: 65px 0;
      align-items: flex-end;

      /*
      min-height: calc(516px - 84px);
      */
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      padding: 50px 0;

      /*
      min-height: calc(100vh - 209px);
      */
    }

    @media (max-width: map.get($grid-breakpoints, "md")) {
      width: calc(100% + 30px);
      min-height: 0;
      padding-top: 0;
      padding-bottom: 0;

      .description-box {
        position: relative;
        bottom: 0;
        width: 100%;
        opacity: 1 !important;
        background: var(--dark-gray);
        margin: 0 -15px;
        padding: 20px 20px 100px;
        left: 0;
        right: 0;

        .mobile-style-wrapper {
          position: absolute;
          bottom: 100%;
          left: 0;
          right: 0;

          h1 {
            margin-bottom: 50px;
          }
        }
      }
    }
  }

  .align {
    @media (min-width: map.get($grid-breakpoints, "lg")) {
      padding-bottom: 111px;
    }
  }

  .btn-play {
    display: block;
    font-size: 0;
    z-index: 1;
    position: absolute;
    top: calc(50% - 36px);
    left: 50%;
    transform: translateX(-50%);

    @include size(35px);

    margin: 0 auto 13px;
    color: rgba(255, 255, 255, 0.75);
    transition: color 0.25s linear;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      @include size(45px);
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      position: absolute;

      @include size(65px);

      left: 50%;
      bottom: 0;
      margin-bottom: 0;
    }

    &:hover {
      color: var(--white);
    }
  }

  .icon-play {
    display: block;

    &::before {
      font-size: 35px;

      @media (min-width: map.get($grid-breakpoints, "md")) {
        font-size: 45px;
      }

      @media (min-width: map.get($grid-breakpoints, "lg")) {
        font-size: 65px;
      }
    }
  }

  p {
    margin: 0;
  }

  &.image-block {
    img {
      width: 100%;
    }

    width: 100%;
    margin-bottom: 20px;
  }
}

.mobile-image-holder-wrapper {
  @media (max-width: map.get($grid-breakpoints, "md")) {
    margin: 0 -15px;
    width: calc(100% + 30px);
  }

  .mobile-image-holder {
    display: none;

    @media (max-width: map.get($grid-breakpoints, "md")) {
      display: block;
      background-size: cover;
      background-position: center;
      width: 100%;
      padding-bottom: 100%;
      height: auto;
    }
  }
}

.scroll-section {
  opacity: 1 !important;

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    margin-top: 0;
  }
}

.guide-line {
  display: block;
  font-size: 0;
  text-indent: -9999px;

  @include size(2px, 20px);

  background: var(--gray-bg);
  background: transparent;

  @include pseudo-element(before, 100%, auto, auto, 50%);

  position: absolute;
  top: 100%;
  left: 50%;
  margin: 0 0 0 -1px;
  z-index: 10;

  @media (min-width: map.get($grid-breakpoints, "md")) {
    height: 29px;
  }

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    height: 68px;
  }

  &::before {
    @include size(18px);

    border: 2px solid var(--gray-bg);
    border-radius: 100%;
    transform: translateX(-50%);

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      @include size(22px);
    }
  }

  &.up {
    background: var(--white);
    background: transparent;
    top: auto;
    bottom: 0;
    height: 30px;

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      height: 50px;
    }

    &::before {
      border-color: var(--gray-bg);
      top: auto;
      bottom: 100%;
      opacity: 0;
      transition: opacity 0.2s;
    }

    &.active {
      &::before {
        opacity: 1;
      }
    }
  }
}

.guide-start {
  display: block;
  left: 50%;
  margin: 0 0 0 -1px;

  @include size(2px, 20px);

  background: var(--gray-bg);
  font-size: 0;
  text-indent: -9999px;
  bottom: 0;

  @include pseudo-element(before, 0, auto, auto, 50%);

  position: absolute;

  &::before {
    @include size(20px, 2px);

    background: var(--gray-bg);
    margin: 0 0 0 -10px;
  }
}

/* info-block styles */
.info-block {
  position: relative;

  .animated-line {
    display: block;
    width: 2px;
    background: var(--gray-bg);
    height: 0;
    left: 50%;
    position: absolute;
    z-index: 10;
    margin-left: -1px;
    top: -50px;

    @media (max-width: map.get($grid-breakpoints, "lg")) {
      top: -30px;
    }
  }

  .guide-line {
    background: var(--gray-bg);

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      height: 65px;
    }
  }
}

.info-item {
  padding: 0;
  background-color: var(--white);

  img {
    width: 100%;
  }

  blockquote {
    font-size: 1.5em;
    font-family: --font-family-sans-serif, sans-serif;
    font-style: italic;
  }

  &.col-sm-offset-1 {
    @media (min-width: map.get($grid-breakpoints, "md")) {
      margin-right: 1em;
    }
  }

  .info-item-content {
    padding: 5px;
  }

  :nth-of-type(2) {
    //padding: 0 1em;
  }
}

.offer-block {
  position: relative;
  background-color: var(--gray-bg);
  padding-bottom: 3em;

  .flex-row {
    position: relative;

    @media (max-width: map.get($grid-breakpoints, "md")) {
      background-color: var(--white);
    }
  }

  .animated-line {
    display: block;
    width: 2px;
    background: var(--gray-bg);
    height: 0;
    left: 50%;
    position: absolute;
    z-index: 10;
    margin-left: -1px;
    top: -20px;

    @media (max-width: map.get($grid-breakpoints, "md")) {
      z-index: 1;
    }
  }

  .row {
    margin-bottom: 1em;
  }

  .guide-line {
    background: var(--gray-bg);
  }

  .header {
    padding-top: 2em;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      padding-top: 94px;
      padding-bottom: 53px;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      padding-top: 151px;
      padding-bottom: 61px;
    }
  }

  h2 {
    margin: 0;
  }

  h3 {
    margin: 0 0 2px;
    color: var(--black);

    @media (min-width: map.get($grid-breakpoints, "md")) {
      margin: 0 0 8px;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      margin: 0 0 11px;
    }
  }

  .article-box {
    min-height: 328px;
    padding-bottom: 110px;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      min-height: 394px;
      padding-bottom: 106px;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      min-height: 615px;
      padding-bottom: 165px;
    }

    @media (max-width: map.get($grid-breakpoints, "md")) {
      border: none;
      font-size: 12px;
      line-height: 17px;
      align-items: flex-end;
    }

    @include pseudo-element(before, 0, 0, 0, 0);

    &::before {
      background: var(--esc-primary);
      z-index: -1;
      transition: opacity 0.8s ease-out;
    }

    &:first-child {
      &::before {
        background: var(--black);
      }
    }

    &.in-viewport {
      .bg-color {
        opacity: 1;
        transition-delay: 0.8s;
      }
    }

    strong {
      font-weight: 400;

      @media (min-width: map.get($grid-breakpoints, "lg")) {
        font-weight: 700;
      }

      &.strong {
        font-weight: 700;
      }
    }

    br {
      display: none;

      @media (min-width: map.get($grid-breakpoints, "lg")) {
        display: block;
      }
    }

    .description {
      padding: 0 5px;

      @media (min-width: map.get($grid-breakpoints, "md")) {
        padding: 0;
      }
    }

    .bg-color {
      /*
      transition: opacity 0.8s ease-out;
      opacity: 0;
      */
    }

    .align {
      padding: 0;

      @media (min-width: map.get($grid-breakpoints, "md")) {
        padding: 150px 35px 0;
      }

      @media (min-width: map.get($grid-breakpoints, "lg")) {
        padding: 235px 44px 0;
      }
    }

    .btn {
      bottom: 37px;

      @media (min-width: map.get($grid-breakpoints, "md")) {
        bottom: 50px;
      }

      @media (min-width: map.get($grid-breakpoints, "lg")) {
        bottom: 101px;
      }
    }
  }
}

.offer-item {
  padding: 0;
  background-color: var(--white);

  img {
    width: 100%;
  }

  h3 {
    padding-top: 5%;
  }

  &.col-sm-offset-1 {
    @media (min-width: map.get($grid-breakpoints, "md")) {
      margin-right: 1em;
    }
  }

  .offer-item-content {
    padding: 5px;
  }

  @media (min-width: map.get($grid-breakpoints, "md")) {
    min-height: 460px;
  }
}

.grid-block {
  padding: 1em 10%;
  background: var(--white);

  .grid-item {
    padding: 0;
    background-color: var(--white);
    border: var(--white) 0.5em solid;

    img {
      width: 100%;
    }

    .grid-item-content {
      h3 {
        min-height: 2.5em;
      }

      p {
        min-height: 7em;
      }
    }
  }
}

.hero {
  padding-left: 0;
  padding-right: 0;
}

.hero-block {
  position: relative;
  margin-bottom: 2em;

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    height: 460px;
  }

  img {
    width: 100%;
  }

  h1,
  h2 {
    margin: 0.4em 0;
  }

  h1 img {
    width: 50%;
  }

  p {
    line-height: 1.5;
  }

  .align {
    padding: 2em 0 0;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      padding-left: 5%;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      padding-left: 5%;
    }

    @media (min-width: map.get($grid-breakpoints, "xxl")) {
      padding-left: 10%;
    }
  }

  .bg-stretch {
    z-index: -1;
  }
}

.media-block,
.success-block,
.chance-block {
  .guide-start {
    background: transparent;
  }

  position: relative;

  .animated-line {
    display: block;
    width: 2px;
    background: var(--gray-bg);
    height: 0;
    left: 50%;
    position: absolute;
    z-index: 10;
    margin-left: -1px;
    top: 0;
  }
}

.success-block .animated-line {
  z-index: 1;
}

.flex-row {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;

  &::after,
  &::before {
    display: block;
  }

  &.row-reverse {
    @media (min-width: map.get($grid-breakpoints, "md")) {
      flex-direction: row-reverse;
    }

    .article-box {
      @media (min-width: map.get($grid-breakpoints, "md")) {
        border-width: 0 0 0 1px;
      }

      &:nth-child(even) {
        @media (min-width: map.get($grid-breakpoints, "md")) {
          border-width: 0 1px 0 0;
        }
      }
    }
  }
}

.article-box {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: var(--white);
  position: relative;

  /* border: solid --white;
  border-width: 0 1px 0 0; */

  text-align: center;

  @include fontsize(14, 19);

  min-height: 100px;
  padding-bottom: 60px;
  padding-top: 15px;
  overflow: hidden;

  @media (min-width: map.get($grid-breakpoints, "md")) {
    align-items: flex-start;
    padding-bottom: 85px;
    padding-top: 36px;
    min-height: 231px;
  }

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    @include fontsize(16, 22);

    padding-top: 60px;
    padding-bottom: 60px;
    min-height: 360px;
    align-items: center;
  }

  &:nth-child(even) {
    border-width: 0 0 0 1px;
  }

  .description a {
    color: var(--white);
    font-weight: 700;
  }

  h3 {
    a:hover {
      color: var(--white);
    }
  }

  &.in-viewport {
    .title,
    .description,
    .btn,
    h3 {
      opacity: 1;
      transform: none;
      transition-delay: 0.2s;
    }

    .description {
      transition-delay: 0.4s;
    }

    .btn {
      transform: translate(-50%, 0);
    }
  }

  .bg-stretch {
    z-index: -1;
    transition: transform 0.5s ease-out;
    background-color: var(--accent-blue);
    backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);

    &.bg-color {
      // background: --esc-primary 50%/169px 27%; // Code was not working due to /auto on end. Which was what was desired!

      @media (min-width: map.get($grid-breakpoints, "md")) {
        background-size: 202px auto;
      }

      @media (min-width: map.get($grid-breakpoints, "lg")) {
        background-size: 314px auto;
      }

      &.site {
        background-size: 299px auto;

        @media (min-width: map.get($grid-breakpoints, "md")) {
          background-size: 359px auto;
        }

        @media (min-width: map.get($grid-breakpoints, "lg")) {
          background-size: 559px auto;
        }
      }
    }
  }

  .align {
    display: block;
    width: 100%;
    padding: 0 15px;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      padding: 0 30px;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      padding: 22px 70px 50px 83px;
    }
  }

  .title {
    display: block;
    color: var(--white);

    @include font(20, 27, 300);

    text-transform: capitalize;
    margin: 0;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      @include fontsize(24, 28);

      margin: 0 0 13px;

      /* transition: opacity 0.5s ease-out, transform 0.5s ease-out;
      opacity: 0;
      transform: translate(0, 20px); */
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      @include fontsize(36, 40);

      margin: 0 0 10px;
    }
  }

  .description {
    display: block;

    /* transition: opacity 0.5s ease-out, transform 0.5s ease-out;
    opacity: 0; */
  }

  .btn {
    position: absolute;
    left: 50%;
    bottom: 19px;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      bottom: 30px;
      transform: translate(-50%, 0);

      /* opacity: 0;
      transform: translate(-50%, 20px);
      transition: opacity 0.5s ease-out, transform 0.5s ease-out, background-color 0.25s linear; */
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      bottom: 50px;
    }

    @media (max-width: map.get($grid-breakpoints, "md")) {
      transform: translate(-50%, 0) !important;
    }

    &.btn-arrow {
      @media (max-width: map.get($grid-breakpoints, "md")) {
        @include size(30px);

        padding: 4px;
      }
    }

    .icon-arrow {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.experience-block {
  position: relative;

  @extend %clearfix;

  &.in-viewport {
    .footer {
      h3 {
        opacity: 1;
        transform: none;
      }
    }
  }

  /* .row {
    @include media('>=768px', '<tablet-md') {
      margin-left: -35px;
      margin-right: -35px;
    }
  } */ // jen - not needed 2021?

  .header {
    padding-top: 68px;
    padding-bottom: 31px;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      padding-top: 94px;
      padding-bottom: 32px;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      padding-top: 154px;
      padding-bottom: 39px;
    }
  }

  .footer {
    h3 {
      text-transform: none;

      @media (min-width: map.get($grid-breakpoints, "md")) {
        /* transition: opacity 0.6s ease-in 2s, transform 0.6s ease-in 2s;
        opacity: 0;
        transform: translateY(15px); */
      }

      @media (min-width: map.get($grid-breakpoints, "lg")) {
        margin: 0 0 0 -5px;
      }

      @media (min-width: map.get($grid-breakpoints, "xxl")) {
        padding: 0;
        margin: 0;
        text-align: center;
      }

      @media (min-width: map.get($grid-breakpoints, "md")) {
        padding-top: 20px;
      }
    }
  }

  h2 {
    margin: 0;
  }

  h3 {
    margin: 0 0 14px;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      margin: 0 0 16px;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      margin: 0 0 28px;
    }

    @media (max-width: map.get($grid-breakpoints, "md")) {
      font-size: 24px;
    }

    &.hidden-xs {
      text-transform: none;

      @media (min-width: map.get($grid-breakpoints, "lg")) {
        margin: 0 0 0 -5px;
      }
    }
  }
}

.twocolumns {
  overflow: hidden;
  margin: 0 0 21px;

  @media (min-width: map.get($grid-breakpoints, "md")) {
    margin: 0 0 25px;
  }

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    margin: 0;
  }

  @media (min-width: map.get($grid-breakpoints, "xl")) {
    width: calc(100% + 52px);
    margin: 0 -7px;
  }

  @media (max-width: map.get($grid-breakpoints, "md")) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.content {
  @media (min-width: map.get($grid-breakpoints, "md")) {
    float: left;
    width: 46.4%;
  }

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    width: 63.6%;
  }
}

.news-block {
  @extend %clearfix;

  margin: 0 0 36px;

  @media (min-width: map.get($grid-breakpoints, "md")) {
    margin: 0 0 20px;
  }

  .more {
    margin: -13px 0 0;
    padding-top: 20px;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      margin: 0;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      margin: -28px 0 0 -5px;
    }
  }
}

.news-holder {
  overflow: hidden;

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 -24px;
  }
}

.news-item {
  @include font(14, 18, 400);

  color: var(--dark-gray);
  margin: 0 0 36px;

  @media (min-width: map.get($grid-breakpoints, "md")) {
    @include fontsize(16, 20);

    /*
    transform: translateY(15px);
    transition: opacity 0.6s ease-in 0.2s, transform 0.6s ease-in 0.2s;
    opacity: 0; */
  }

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    // width: 50%;

    @include fontsize(17, 24);

    padding: 0 10px 35px;
    min-height: 337px;
    margin: 0;
  }

  .in-viewport & {
    opacity: 1;
    transform: translateY(0);

    &:nth-child(2) {
      transition-delay: 0.5s;
    }

    &:nth-child(3) {
      transition-delay: 0.6s;
    }

    &:nth-child(4) {
      transition-delay: 0.7s;
    }
  }

  .visual {
    margin: 0 0 11px;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      margin: 0 0 15px;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      margin: 0 0 19px;
    }

    a {
      @include vertical();

      width: 100%;
      transition: opacity 0.25s linear;

      &:hover {
        opacity: 0.8;
      }
    }

    img {
      @include img-styles();

      max-width: inherit;
      width: 100%;
    }
  }

  p {
    margin: 0;
  }

  a {
    color: var(--dark-gray);
    transition: color 0.25s linear;
    font-weight: normal;

    &:hover {
      color: var(--accent-blue);
    }
  }
}

.sidebar {
  @media (min-width: map.get($grid-breakpoints, "md")) {
    float: right;
    width: 51%;
    margin: 0;
  }

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    width: 34.3%;
  }
}

.widget {
  position: relative;
  margin: 0 0 15px;

  &.events-widget {
    .more {
      @media (min-width: map.get($grid-breakpoints, "lg")) {
        margin: 0 0 0 20px;
      }
    }
  }
}

.events-list {
  // background: var(--white);
  padding: 30px 15px 20px;
  color: var(--dark-gray);

  @include fontsize(14, 20);

  margin: 0 -20px 26px;

  @media (min-width: map.get($grid-breakpoints, "md")) {
    @include fontsize(16, 20);

    padding: 17px 15px 15px;
    margin: 0 0 36px;
  }

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    @include fontsize(18, 24);

    margin: 0 0 28px;
    padding: 0 15px 40px;
  }

  li {
    padding: 12px 0 13px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      padding: 15px 0;

      /* opacity: 0;
      transform: translateY(15px);
      transition: opacity 0.6s ease-in 0.9s, transform 0.6s ease-in 0.9s; */
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      padding: 25px 0;
    }

    /*
    @include pseudo-element(before, 12px, auto, 13px, 25%);

    &::before {
      width: 2px;
      background: var(--accent-blue);

      @media (min-width: map.get($grid-breakpoints, "md")) {
        top: 15px;
        bottom: 15px;
        left: 26%;
      }

      @media (min-width: map.get($grid-breakpoints, "lg")) {
        bottom: 25px;
        top: 25px;
        left: 25.1%;
      }
    }
    */

    .in-viewport & {
      opacity: 1;
      transform: translateY(0);

      &:nth-child(2) {
        transition-delay: 1s;
      }

      &:nth-child(3) {
        transition-delay: 1.2s;
      }

      &:nth-child(4) {
        transition-delay: 1.4s;
      }
    }
  }

  .time {
    display: block;
    width: 26%;
    text-align: center;

    @include font(20, 20, 300);

    padding: 10px 6% 10px 0;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      @include fontsize(26, 26);

      padding: 7px 5% 7px 0;
    }

    .month {
      font-weight: 400;
      display: block;
      text-transform: uppercase;

      @include fontsize(16, 18);

      margin: 0 0 10px;

      @media (min-width: map.get($grid-breakpoints, "md")) {
        @include fontsize(18, 20);
      }
    }
  }

  .data {
    // width: 74%;
    padding: 0 0 10px 20px;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      padding: 0 5px 0 27px;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      padding: 0 10px 0 24px;
    }

    @media (min-width: map.get($grid-breakpoints, "xxl")) {
      padding: 0 20px 0 24px;
    }
  }

  a {
    color: var(--dark-gray);
    font-weight: 400;
    transition: color 0.25s linear;

    &:hover {
      color: var(--accent-blue);
    }
  }
}

.more {
  padding: 0 5px;
  font-size: 16px;
  position: relative;
  z-index: 1;

  @include vertical();

  color: var(--accent-blue);

  @include font(14, 30, 700);

  text-transform: none;
  transition: color 0.25s linear, opacity 0.25s linear;

  @media (min-width: map.get($grid-breakpoints, "md")) {
    @include fontsize(18, 30);

    /* opacity: 0;
    transform: translateY(15px);
    transition: opacity 0.6s ease-in 1.6s, transform 0.6s ease-in 1.6s; */
  }

  .icon {
    @include vertical(middle);

    font-size: 30px;
    margin: 0 16px 0 0;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      margin: 0 12px 0 0;
    }

    &.icon-events {
      @media (max-width: map.get($grid-breakpoints, "md")) {
        font-size: 28px;
      }
    }
  }

  &:hover {
    color: var(--accent-blue);
  }

  .in-viewport & {
    opacity: 1;
    transform: none;
  }
}

.media-block {
  position: relative;

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    padding-top: 34px;
  }

  &.in-viewport {
    .media-box {
      .date,
      .description,
      .icon,
      .bg-stretch,
      .media-box-inner > a {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .flex-row {
    @media (min-width: map.get($grid-breakpoints, "md")) {
      display: block;
    }
  }

  .btn-list {
    padding: 30px 0 25px;
    margin: 0 -20px;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      margin: 0;
      padding: 32px 0 25px;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      padding: 30px 0 22px;
    }

    > li {
      padding-left: 21px;
      padding-right: 33px;

      @media (min-width: map.get($grid-breakpoints, "md")) {
        padding-left: 73px;
        padding-right: 54px;
      }

      @media (min-width: map.get($grid-breakpoints, "lg")) {
        padding-left: 62px;
        padding-right: 62px;
      }
    }

    .more {
      opacity: 1;
      transform: none;

      @media (min-width: map.get($grid-breakpoints, "xl")) {
        padding-top: 16px;
        padding-bottom: 18px;
      }

      .icon {
        font-size: 29px;
        margin: 0 9px 0 0;

        @media (min-width: map.get($grid-breakpoints, "md")) {
          font-size: 28px;
        }
      }
    }
  }
}

.instagram-feed,
.media-block .twitter-feed {
  display: none;
}

.media-box {
  background: var(--esc-primary);
  text-align: center;
  overflow: hidden;
  position: relative;
  color: var(--white);

  @include fontsize(12, 17);

  z-index: 1;

  .everyday-caption {
    display: none;
  }

  .insta-img {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    z-index: 2;
  }

  padding: 0 0 25%;
  min-height: 0;

  .overlay-link {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.1);
    opacity: 0;
    transition: opacity 0.3s linear;
    text-indent: -99999px;
    z-index: 10;
  }

  .media-box-inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    > a {
      transition: opacity 0.6s ease-in 0.4s, visibility 0.6s ease-in 0.4s;
      opacity: 0;
      visibility: hidden;
    }
  }

  &:hover {
    .overlay-link {
      opacity: 1;
    }
  }

  @media (min-width: map.get($grid-breakpoints, "md")) {
    @include fontsize(14, 19);

    .media-box-inner {
      padding-top: 38px;
      padding-bottom: 50px;
    }
  }

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    @include fontsize(16, 22);

    .media-box-inner {
      padding-top: 44px;
    }
  }

  @media (min-width: map.get($grid-breakpoints, "xl")) {
    .media-box-inner {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  @media (min-width: map.get($grid-breakpoints, "xxl")) {
    .media-box-inner {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  @media (max-width: map.get($grid-breakpoints, "xl")) {
    &.col-md-3 {
      width: 33.3333% !important;
      padding-bottom: 33.3333% !important;
    }

    .media-box-inner {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 30px;
    }

    &:nth-child(4),
    &:nth-child(8) {
      display: none !important;
    }
  }

  @media (max-width: map.get($grid-breakpoints, "lg")) {
    .media-box-inner {
      padding-top: 20px;
    }
  }

  @media (max-width: map.get($grid-breakpoints, "md")) {
    &.col-md-3 {
      width: 50% !important;
      padding-bottom: 50% !important;
    }
  }

  &.insta-box {
    background: var(--gray-bg);
  }

  &.bg-gray {
    background: var(--dark-gray);
  }

  &.bg-blue {
    background: var(--accent-blue);
  }

  &.order-1 {
    @media (max-width: map.get($grid-breakpoints, "md")) {
      order: 1;
    }
  }

  &.order-2 {
    @media (max-width: map.get($grid-breakpoints, "md")) {
      order: 2;
    }
  }

  &.order-3 {
    @media (max-width: map.get($grid-breakpoints, "md")) {
      order: 3;
    }
  }

  &.order-4 {
    @media (max-width: map.get($grid-breakpoints, "md")) {
      order: 4;
    }
  }

  &.order-5 {
    @media (max-width: map.get($grid-breakpoints, "md")) {
      order: 5;
    }
  }

  &.order-6 {
    @media (max-width: map.get($grid-breakpoints, "md")) {
      order: 6;
    }
  }

  br {
    display: none;

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      display: block;
    }
  }

  p {
    margin: 0;
  }

  a {
    color: var(--white);
    transition: color 0.25s linear;
    font-weight: 700;

    &:hover {
      color: var(--black);
    }
  }

  .date {
    display: none;
    text-transform: uppercase;

    @include font(16, 20, 300);

    @media (min-width: map.get($grid-breakpoints, "md")) {
      margin: 0 0 19px;
      display: block;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.6s ease-in 0.4s, visibility 0.6s ease-in 0.4s;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      margin: 0 0 22px;
    }

    @media (max-width: map.get($grid-breakpoints, "lg")) {
      margin-bottom: 12px;
    }
  }

  .description {
    display: block;
    max-height: 85px;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      opacity: 0;
      max-height: inherit;
      overflow: visible;
      visibility: hidden;
      transition: opacity 0.6s ease-in 0.4s, visibility 0.6s ease-in 0.4s;
    }
  }

  .icon {
    color: var(--white);

    @include vertical();

    position: absolute;
    z-index: 3;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
    bottom: 10px;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.3);

    @media (min-width: map.get($grid-breakpoints, "md")) {
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.6s ease-in 0.4s, visibility 0.6s ease-in 0.4s;
      bottom: 18px;
      font-size: 24px;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      font-size: 28px;
      bottom: 12px;
    }

    @media (min-width: map.get($grid-breakpoints, "xxl")) {
      bottom: 15px;
    }
  }
}

.bg-stretch {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: 50% 50%/cover no-repeat;
}

.container-holder {
  max-width: 968px;
  margin: 0 auto;
}

.success-block {
  position: relative;
  background: var(--white);

  &::before {
    display: block;
  }

  .header {
    position: relative;
    padding-top: 66px;
    padding-bottom: 32px;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      padding-top: 95px;
      padding-bottom: 35px;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      padding-top: 138px;
      padding-bottom: 51px;
    }
  }
}

.gallery-block.in-viewport {
  .slide {
    &.active {
      z-index: 2;

      .blockquote {
        opacity: 1;

        @media (min-width: map.get($grid-breakpoints, "md")) {
          transition: opacity 0.6s ease-in 0.4s, transform 0.6s ease-in 0.4s;
          transform: translateX(15px);
        }
      }
    }
  }
}

.assessment-block {
  position: relative;
  background: var(--white);
  padding-bottom: 26px;

  @media (min-width: map.get($grid-breakpoints, "md")) {
    padding-bottom: 42px;
  }

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    padding-bottom: 76px;
  }

  @include bg-color(before, var(--white));

  &::before {
    display: block;
  }

  .container-holder {
    max-width: 950px;
  }

  &.in-viewport {
    .col {
      opacity: 1;

      &:nth-child(2) {
        transition-delay: 0.6s;
      }

      &:nth-child(3) {
        transition-delay: 0.8s;
      }
    }
  }

  .header {
    @include font(16, 20, 300);

    padding-top: 70px;
    color: var(--black);
    padding-bottom: 36px;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      @include fontsize(20, 27);

      padding-top: 94px;
      padding-bottom: 53px;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      @include fontsize(24, 34);

      padding-top: 139px;
      padding-bottom: 71px;
    }

    p {
      margin: 0;
    }

    br {
      display: none;

      @media (min-width: map.get($grid-breakpoints, "md")) {
        display: block;
      }
    }
  }

  h2 {
    margin: 0 0 18px;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      margin: 0 0 10px;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      margin: 0 0 29px;
    }
  }

  h3 {
    @include fontsize(24, 27);

    margin: 0 0 13px;

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      @include fontsize(30, 34);

      margin: 0 0 9px;
    }
  }

  .col {
    text-align: center;
    color: var(--black);

    @include font(14, 19, 300);

    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 22px;

    /* opacity: 0;
    transition: opacity 0.7s ease-out 0.4s; */

    @media (min-width: map.get($grid-breakpoints, "md")) {
      @include fontsize(16, 19);

      padding-left: 20px;
      margin-bottom: 0;
      padding-right: 20px;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      @include fontsize(20, 27);

      padding-left: 25px;
      padding-right: 25px;
    }
  }

  .icon-holder {
    margin: 0 0 18px;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      margin: 0 0 24px;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      margin: 0 0 46px;
    }
  }

  .icon {
    @include vertical();

    font-size: 70px;
    color: var(--esc-primary);

    @media (min-width: map.get($grid-breakpoints, "md")) {
      font-size: 68px;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      font-size: 80px;
    }
  }
}

.chance-block {
  position: relative;
  z-index: 1;
  color: var(--black);

  @include font(16, 22, 300);

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    @include fontsize(24, 34);
  }

  .bg-stretch {
    z-index: -1;
  }

  &.in-viewport {
    h2,
    .text,
    .btn-list {
      opacity: 1;
      transform: none;
    }
  }

  .guide-line {
    bottom: auto;
    top: 0;
    background: var(--white);
    background: transparent;

    &::before {
      border-color: var(--gray-bg);
    }
  }

  h2 {
    color: var(--black);

    @include fontsize(26, 30);

    margin: 0 0 8px;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      @include fontsize(36, 40);

      margin: 0 0 5px;

      /* transform: translateY(15px);
      transition: opacity 0.6s ease-out 0.8s, transform 0.6s ease-out 0.8s;
      opacity: 0; */
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      @include fontsize(52, 56);

      margin: 0 0 18px;
    }
  }

  .text {
    display: block;
    margin: 0 0 25px;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      margin: 0 0 42px;

      /* transform: translateY(15px);
      transition: opacity 0.6s ease-out 0.6s, transform 0.6s ease-out 0.6s;
      opacity: 0; */
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      margin: 0 0 105px;
    }
  }

  .holder {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      align-items: center;
      min-height: 232px;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      align-items: flex-start;
      min-height: 364px;
    }
  }

  .align {
    display: block;
    width: 100%;
    padding: 63px 0 11px;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      padding: 110px 0 20px;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      padding: 142px 0 50px;

      .text {
        padding-left: 220px;
        padding-right: 220px;
      }
    }
  }

  br {
    display: none;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      display: block;
    }
  }

  .btn-list {
    margin: 0;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
      justify-content: center;

      /* transform: translateY(15px);
      transition: opacity 0.6s ease-out 0.4s, transform 0.6s ease-out 0.4s; */
    }

    li {
      padding: 0 10px 12px;

      @media (min-width: map.get($grid-breakpoints, "md")) {
        padding: 0 10px;
      }

      @media (min-width: map.get($grid-breakpoints, "lg")) {
        padding: 0 15px;
      }
    }
  }

  /*
  .btn {
    @media (min-width: map.get($grid-breakpoints, "lg")) {
      min-width: 300px;

      @include fontsize(22, 26);

      padding: 26px 15px;
    }
  }
*/

  .chance-image {
    height: 364px;
  }

  .chance-text {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.large-text {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.5;

  @media (max-width: map.get($grid-breakpoints, "md")) {
    font-size: 18px;
    line-height: 1.5;
  }
}

hr {
  border: 0;
  background: var(--primary-blue);
  height: 3px;
  opacity: 1;
}

.content-block {
  // margin-bottom: 20px; remove jen 2021
  padding-left: 30px;
  padding-right: 30px;

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    padding-left: 0;
    padding-right: 0;
  }
}

.jcf-scrollable-wrapper {
  margin: 0 0 30px;

  .jcf-scrollbar-inc,
  .jcf-scrollbar-dec {
    @include vertical();

    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    padding: 0 20px 0 0;

    @include pseudo-element(after, 3px, 5px, auto, auto);

    &::after {
      @include size(12px);

      border: solid;
      border-width: 2px 2px 0 0;
      border-color: var(--dark-gray) var(--dark-gray) transparent transparent;
      transform: rotate(45deg);
    }

    &::before {
      cursor: pointer;
      content: 'swipe for more';

      @include vertical();

      color: var(--dark-gray);

      @include font(13, 15, 700);

      //    pointer-events: none; // Valid Only for SVG
    }
  }

  .jcf-scrollbar-dec {
    padding: 0 0 0 20px;
    margin: 0 0 0 -20px;

    &::before {
      opacity: 0;
      visibility: hidden;
    }

    &::after {
      border-width: 0 0 2px 2px;
      border-color: transparent transparent var(--dark-gray) var(--dark-gray);
      right: auto;
      left: 5px;
    }
  }
}

.jcf-scrollbar.jcf-scrollbar-horizontal {
  padding: 15px 0 0;
  position: relative;
}

.table-holder {
  margin: 0 -15px 0 0;
  overflow: auto !important;
  height: auto !important;
  position: relative;

  @media (max-width: map.get($grid-breakpoints, "lg")) {
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 40px;
      height: 100%;
      right: 0;
      top: 0;
      opacity: 0.3;
      background: url(../images/shadow.png) right center/100% no-repeat;
      transition: opacity 0.3s;
    }
  }

  &.jcf-scrollable {
    height: auto !important;
  }
}

.table-wrapper {
  overflow: scroll !important;
  -webkit-overflow-scrolling: touch;
  touch-action: auto !important;

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    overflow: auto !important;
  }
}

.swipe-wrapper {
  text-align: center;

  @media (max-width: map.get($grid-breakpoints, "lg")) {
    position: relative;

    &::after {
      content: "";
      width: 50px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      background: var(--white);
      display: block;
      z-index: 10;
    }
  }

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    display: none;
  }
}

.swipe-for-more {
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 20px 20px 20px 0;

  &::after {
    content: "";
    width: 12px;
    height: 12px;
    border: solid;
    border-width: 2px 2px 0 0;
    border-color: var(--dark-gray) var(--dark-gray) transparent transparent;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    display: block;
    top: 50%;
    margin-top: -5px;
    bottom: auto;
    left: auto;
    right: 5px;
  }
}

.scroll-link {
  display: block;
  color: var(--dark-gray);
  padding: 0 18px 0 0;
  margin: 15px auto 0;
  max-width: 125px;

  @include pseudo-element(before, 7px, 5px, auto, auto);

  &::before {
    @include size(12px);

    border: solid;
    border-width: 2px 2px 0 0;
    border-color: var(--dark-gray) var(--dark-gray) transparent transparent;
    transform: rotate(45deg);
  }
}

.table-box {
  table-layout: auto;
  width: 910px;

  @media (min-width: map.get($grid-breakpoints, "xxl")) {
    width: 100%;
  }

  thead {
    @include font(16, 20, 700);

    background: var(--esc-primary);
    color: var(--white);

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      @include fontsize(18, 22);
    }
  }

  th {
    padding: 15px 31px;
    vertical-align: middle;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 700;
    background: var(--esc-primary);
    color: var(--white);

    @media (min-width: map.get($grid-breakpoints, "md")) {
      padding: 19px 31px;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      padding: 19px 28px;
      font-size: 22px;
      line-height: 1.5;
    }

    a {
      color: var(--white);
    }
  }

  tbody {
    color: var(--dark-gray);

    @include font(14, 18, 400);

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      @include fontsize(16, 20);
    }

    tr {
      border-bottom: 1px solid var(--dark-gray);
    }
  }

  td {
    vertical-align: middle;
    padding: 20px 31px 19px;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      padding: 24px 31px 19px;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      padding: 20px 29px;
    }
  }
}

th {
  p {
    margin-bottom: 0;
  }
}

.cta-box {
  margin-bottom: 40px;

  @media (min-width: map.get($grid-breakpoints, "md")) {
    margin-bottom: 50px;
  }

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    margin-bottom: 60px;
  }

  .visual {
    max-width: 288px;
    margin: 0 auto 20px;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      float: left;
      width: 34.4%;
      max-width: none;
      padding-top: 8px;
      margin: 0;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      width: 31.7%;
    }

    @media (max-width: map.get($grid-breakpoints, "md")) {
      max-width: none;

      picture,
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .text-holder {
    overflow: hidden;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      padding: 0 0 0 4.5%;
    }

    @media (max-width: map.get($grid-breakpoints, "md")) {
      line-height: 1.5;
    }
  }
}

.quote-block {
  position: relative;

  &.in-viewport {
    .blockquote {
      opacity: 1;
      transform: none;
    }
  }

  .text-holder {
    @media (min-width: map.get($grid-breakpoints, "md")) {
      float: left;
      width: 65%;
      margin: -5px 0 0;
    }

    p {
      @media (min-width: map.get($grid-breakpoints, "md")) {
        margin: 0 0 28px;
      }
    }
  }

  .blockquote {
    /* opacity: 0;
    transition: opacity 0.8s ease-out 0.4s; */

    @media (min-width: map.get($grid-breakpoints, "md")) {
      float: right;
      width: 31%;

      /* transform: translateX(100%);
      transition: opacity 0.7s ease-out, transform 0.7s ease-out; */
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      width: 31%;
    }
  }
}

.blockquote {
  margin: 0;
  padding: 30px 26px;
  text-align: center;
  border: none;

  @include font(18, 24, 400);

  color: var(--white);
  background: var(--accent-blue);

  @media (min-width: map.get($grid-breakpoints, "md")) {
    @include fontsize(18, 24);

    padding: 28px 20px 33px;
  }

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    @include fontsize(28, 38);

    padding: 38px 20px 47px;
  }

  q {
    display: block;
    margin: 0 0 19px;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      margin: 0 0 26px;
    }
  }

  cite {
    display: block;
    font-style: normal;
    position: relative;

    @include fontsize(14, 18);

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      @include fontsize(18, 22);
    }

    &::before {
      content: '';

      @include vertical(middle);
      @include size(15px, 1px);

      background: var(--white);
      margin: 0 6px 0 0;
    }
  }
}

.cycle-gallery {
  position: relative;
  border-bottom: 2px solid var(--white);
  padding: 0 0 32px;
  margin: 0 -15px 13px;

  @media (min-width: map.get($grid-breakpoints, "md")) {
    padding: 0 0 33px;
    margin: 0 0 20px;
  }

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    padding: 0 0 56px;
    margin: 0 0 20px;
  }

  .mask {
    position: relative;
    overflow: hidden;
    width: 100%;

    @media (max-width: map.get($grid-breakpoints, "md")) {
      margin: 0 auto;
    }
  }

  .slideset {
    width: 99999px;
    float: left;
  }

  .slide {
    width: 100%;
    float: left;
    transform: translateZ(0);
    backface-visibility: hidden;

    &.active {
      z-index: 1;
    }
  }

  .visual {
    margin: 0 0 5px;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      margin: 0 0 15px;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      margin: 0 0 22px;
    }

    @media (max-width: map.get($grid-breakpoints, "md")) {
      width: 100%;

      picture,
      img {
        width: 100%;
      }
    }
  }

  .caption {
    @include fontsize(14, 24);

    padding: 0 26px;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      padding: 0;
      line-height: 1.5;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      @include fontsize(16, 20);
    }

    strong {
      font-weight: 600;
    }

    p {
      margin: 0;
    }
  }

  .btn-next,
  .btn-prev {
    position: absolute;
    display: block;

    @include size(30px);
    @include hide-text();

    border-radius: 100%;
    background: var(--esc-primary);
    z-index: 2;
    top: 50%;
    margin-top: -15px;
    transition: background 0.25s linear;

    @include pseudo-element(before, 50%, auto, auto, 50%);

    @media (min-width: map.get($grid-breakpoints, "md")) {
      @include size(42px);

      margin: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      margin: -13px 0 0;
    }

    &::before {
      @include size(8px);

      border: solid;
      transform: translate(-50%, -50%) rotate(45deg);

      @media (min-width: map.get($grid-breakpoints, "md")) {
        @include size(10px);
      }
    }

    &:hover {
      background: lighten(#ff9800, 10%);

      &::after {
        background: lighten(#ff9800, 10%);
      }
    }
  }

  .btn-next {
    right: 10px;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      right: -16px;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      right: 10px;
    }

    @media (min-width: map.get($grid-breakpoints, "xxl")) {
      right: -21px;
    }

    &::before {
      border-width: 0;
      margin: 0 0 0 -1px;
      background: var(--white);
    }

    &::after {
      content: "";

      @include size(8px);

      background: var(--esc-primary);
      position: absolute;
      display: block;
      top: 50%;
      bottom: auto;
      left: 50%;
      right: auto;
      transform: translate(-50%, -50%) rotate(45deg);
      margin-left: -4px;
      transition: all 0.25s linear;

      @media (min-width: map.get($grid-breakpoints, "md")) {
        @include size(10px);
      }
    }
  }

  .btn-prev {
    left: 10px;

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      left: -16px;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      left: 10px;
    }

    @media (min-width: map.get($grid-breakpoints, "xxl")) {
      left: -21px;
    }

    &::before {
      border-width: 0;
      margin: 0 0 0 1px;
      background: var(--white);
    }

    &::after {
      content: "";

      @include size(8px);

      background: var(--esc-primary);
      position: absolute;
      display: block;
      top: 50%;
      bottom: auto;
      left: 50%;
      right: auto;
      transform: translate(-50%, -50%) rotate(45deg);
      margin-left: 4px;
      transition: all 0.25s linear;

      @media (min-width: map.get($grid-breakpoints, "md")) {
        @include size(10px);
      }
    }
  }
}

/* ---------------------------------------------------------
   3. Print styles
   --------------------------------------------------------- */

/*
@include media('print') {
  *,
  *::before,
  *::after,
  *::first-letter,
  *::first-line {
    background: transparent !important;
    color: --black !important;
*/

/* Black prints faster:   http://www.sanbeiji.com/archives/953 */

/*
    box-shadow: none !important;
    text-shadow: none !important;
    opacity: 1 !important;
    visibility: visible !important;
    transform: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]::after {
    content: " (" attr(href) ")";
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }
*/

/*
 * Don't show links that are fragment identifiers,
 * or use the `javascript:` pseudo protocol
 */

/*
  a[href^="#"]::after,
  a[href^="javascript:"]::after {
    content: "";
  }

  .row {
    &::after {
      display: block !important;
      clear: both;
    }
  }

  pre,
  blockquote {
    border: 1px solid --dark-gray;
    page-break-inside: avoid;
  }
*/

/*
 * Printing Tables:
 * http://css-discuss.incutio.com/wiki/Printing_Tables
 */

/*
  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  .container {
    width: 100% !important;

    .row {
      margin: 0 !important;
    }
  }

  .cta-header,
  #nav {
    display: none !important;
  }

  .logo {
    max-width: 256px !important;
    margin: 0 auto !important;
    float: none !important;
  }

  .article-box {
    display: block !important;
    min-height: 1px !important;
    padding: 0 !important;
  }

  #header {
    border: none !important;
    position: static !important;

    * {
      border: none !important;
    }

    .top-panel {
      padding: 5px 0 !important;
    }

    .nav-holder {
      display: none !important;
    }
  }

  .btn {
    display: none !important;
  }

  .add-nav {
    display: none !important;
  }

  .banner {
    display: block !important;
    padding: 15px 0 30px !important;
    overflow: visible !important;

    .align,
    .holder {
      padding: 0 !important;
      display: block !important;
      min-height: 1px !important;
      margin: 0 !important;
    }

    .btn-play {
      display: none !important;
    }
  }

  #main {
    padding: 0 !important;
  }

  .guide-line,
  .guide-start {
    display: none !important;
  }

  .social-networks {
    display: none !important;
  }

  .offer-block {
    display: block !important;

    .article-box {
      min-height: 1px !important;
      display: block !important;
      margin: 0 0 20px !important;
      padding: 0 10px !important;
    }

    .align {
      padding: 5px 0 !important;
    }

    .header {
      padding: 0 0 30px !important;
    }

    strong {
      font-weight: 700 !important;
    }
  }

  .info-block {
    margin: 0 0 30px !important;
  }

  .copyright {
    position: static !important;
    width: 100% !important;
    text-align: center !important;
  }

  #footer {
    padding: 0 !important;
  }

  .bottom-section {
    padding: 10px 0 0 !important;
    background-color: var(--dark-gray);

    a {
      font-weight: normal;
    }
  }

  .aside {
    margin: 0 0 10px !important;
    padding: 0 !important;

    .logo {
      max-width: 180px !important;
      margin: 0 auto 15px !important;
    }

    address {
      a {
        display: block !important;
        margin: 5px 0 0 !important;
      }
    }
  }

  .footer-nav {
    display: none !important;
  }

  .experience-block {
    .header {
      padding: 0 0 20px !important;
    }
  }

  .twocolumns {
    .content {
      width: 100% !important;
      float: none !important;
      display: none !important;
    }

    .sidebar {
      width: 100% !important;
      float: none !important;
    }
  }

  .more {
    display: none !important;
  }

  .media-box {
    &.insta-box {
      display: none !important;
    }
  }

  .events-list {
    li {
      padding: 15px 0 !important;

      &::before {
        display: none;
      }
    }

    .time {
      width: 110px !important;
      padding: 5px !important;
    }

    .data {
      width: calc(100% - 110px);
      padding: 5px 10px;
    }
  }

  .success-block {
    display: none !important;

    .header {
      padding: 0 0 30px !important;
    }
  }

  .chance-block {
    display: none !important;
  }

  .slideshow {
    min-height: 1px !important;

    .bg-stretch {
      display: none !important;
    }

    .blockquote {
      width: 100% !important;
      margin: 0 auto !important;
      padding: 20px !important;
    }

    .icon-play {
      display: none !important;
    }

    .head {
      display: none !important;
    }
  }

  .header {
    padding-top: 0 !important;
    padding-bottom: 20px !important;
  }

  .assessment-block {
    margin-bottom: 20px !important;
  }

  .media-block {
    margin-bottom: 35px !important;
  }

  .breadcrumb {
    display: none !important;
  }

  .open-close {
    display: none !important;
  }

  .cta-box {
    margin: 0 0 20px !important;

    .visual {
      width: auto !important;
    }
  }

  .cycle-gallery {
    .mask {
      height: auto !important;
      -webkit-user-drag: auto !important;
    }

    .slideset {
      position: static !important;
      margin: 0 !important;
      height: auto !important;
      width: auto !important;
      float: none !important;
    }

    .slide {
      display: none !important;

      &:first-child {
        display: block !important;
        float: none !important;
        width: 100% !important;
        position: static !important;
      }
    }
  }

  .intro-block {
    margin-bottom: 20px !important;
  }

  .jcf-scrollable-wrapper,
  .jcf-scrollable {
    width: auto !important;
    height: auto !important;
  }

  .table-block {
    page-break-inside: avoid;
  }

  .blockquote {
    padding: 10px !important;
  }

  .table-box {
    th {
      padding: 15px 10px !important;

      &:nth-child(1) {
        width: 20% !important;
      }

      &:nth-child(2) {
        width: 20% !important;
      }

      &:nth-child(3) {
        width: 60% !important;
      }
    }

    td {
      padding: 12px 10px !important;
    }
  }

  .content-block {
    margin: 0 0 15px !important;
  }

  .intro-video-holder {
    display: none !important;
  }
}
*/

.intro-block .bg-stretch,
.chance-block .bg-stretch {
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
  }

  .align {
    position: relative;
    z-index: 10;
  }
}

.no-js {
  .guide-line {
    background: var(--gray-bg);

    &.up {
      background: var(--white);

      &::before {
        opacity: 1;
      }
    }
  }

  .offer-block {
    .article-box {
      border: solid var(--white);
      border-width: 0 1px 0 0;

      &:nth-child(odd) {
        border-width: 0 0 0 1px;
      }
    }
  }

  .info-block {
    .article-box {
      border: solid var(--white);
      border-width: 0 1px 0 0;

      &:nth-child(even) {
        border-width: 0 0 0 1px;
      }
    }
  }
}

.gray-block {
  background: #000 center 20%/cover no-repeat;
  overflow: hidden;
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(-180deg, rgba(87, 89, 88, 0.95) 3%, rgba(87, 89, 88, 0.95) 100%);
    z-index: 2;
  }

  section > div {
    position: relative;
    z-index: 3;
  }

  .col-xs-12 {
    padding: 0;
  }

  padding-top: 70px;
  padding-bottom: 70px;

  @media (max-width: map.get($grid-breakpoints, "lg")) {
    padding-top: 60px;
    padding-bottom: 40px;
  }

  @media (max-width: map.get($grid-breakpoints, "md")) {
    padding-top: 55px;
    padding-bottom: 0;
  }

  color: var(--white);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--white);
  }

  .header {
    padding-top: 0;
    margin-bottom: 30px;

    @media (max-width: map.get($grid-breakpoints, "md")) {
      padding: 0 15px;
    }
  }

  .slideshow::after {
    content: none;
  }

  .blockquote {
    background: var(--accent-blue);
  }

  .slideshow {
    @media (max-width: map.get($grid-breakpoints, "md")) {
      margin-left: 0;
      margin-right: 0;

      .head {
        background: var(--white);
        color: var(--esc-primary);
      }

      .switcher {
        a {
          color: var(--esc-primary);
        }
      }

      .counter {
        color: var(--esc-primary);
      }
    }
  }

  .slideshow .icon-play {
    margin: 0;
  }

  .btn-prev,
  .btn-next {
    &::after {
      border: 0;
      width: 14px;
      height: 14px;
      margin-top: -7px;
      margin-left: -7px;
      background: url(../images/icons/previous-blue_video@2x.png) 100% no-repeat;
    }
  }

  .btn-next {
    &::after {
      background-image: url(../images/icons/next-blue_video@2x.png);
    }
  }
}

.partnership-block {
  padding-top: 90px;
  padding-bottom: 90px;

  @media (max-width: map.get($grid-breakpoints, "lg")) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media (max-width: map.get($grid-breakpoints, "md")) {
    .container-holder {
      padding-bottom: 30px;
    }
  }

  img {
    width: 100%;

    @media (max-width: map.get($grid-breakpoints, "md")) {
      max-width: 320px;
      margin: 0 auto;
      display: block;
    }
  }

  @media (min-width: map.get($grid-breakpoints, "md")) {
    .row {
      margin-left: -15px !important;
      position: static !important;
      height: auto !important;

      .slide {
        position: static !important;
        left: auto !important;
        width: 33.3333333333% !important;
      }
    }

    .pagination-holder {
      display: none;
    }

    .mask {
      overflow: visible !important;
      height: auto !important;
    }
  }

  @media (max-width: map.get($grid-breakpoints, "md")) {
    .pagination-holder {
      text-align: center;
      overflow: hidden;

      .pagination {
        display: inline;
        overflow: hidden;

        ul {
          display: inline;
          padding: 0;
        }

        li {
          display: inline-block;
          margin: 20px 10px;

          &.active {
            a {
              background: var(--black);
            }
          }
        }

        a {
          width: 10px;
          height: 10px;
          display: block;
          text-indent: -9999px;
          background: var(--white);
          border-radius: 50%;
        }
      }
    }
  }
}

.link-label {
  font-size: 16px;
}

.desc {
  font-size: 14px;
}

.questions-block {
  padding-top: 40px;
  padding-bottom: 100px;
  background: center 20%/cover no-repeat;
  position: relative;
  display: flex;

  .row {
    position: relative;
    z-index: 3;
    margin: 0 auto;
  }

  @media (max-width: map.get($grid-breakpoints, "lg")) {
    padding-top: 60px;
    padding-bottom: 50px;
  }

  @media (max-width: map.get($grid-breakpoints, "md")) {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0.76) 10%, #fff 100%);
    z-index: 0;
  }

  .btn-list {
    margin: 0;

    @include btn-list-tablet();

    li {
      padding: 0 10px 12px;
    }
  }

  .container {
    position: relative;
    z-index: 3;
  }

  .icon-email {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    background: url(../images/icons/email@2x.png) center/100% no-repeat;
    position: relative;
    top: -1px;
  }

  .btn-email {
    text-transform: lowercase;
  }

  header {
    background: none;
    border-bottom: 0;
  }
}

.attention-block {
  max-width: 526px;
  width: 100%;
  margin: 30px auto;
  padding: 35px 43px;
  background: var(--esc-primary);
  color: var(--black);
  position: relative;

  @media (max-width: map.get($grid-breakpoints, "md")) {
    padding: 30px 10px;
    max-width: none;
  }

  p {
    color: var(--black);
    font-size: 1.25rem;
    margin-bottom: 1.5em;
    font-family: --font-family-serif, sans-serif;

    @media (max-width: map.get($grid-breakpoints, "lg")) {
      font-size: 18px;
      line-height: 1.5;
    }
  }

  .phone {
    font-size: 32px;
    line-height: 1.5;
    color: var(--black);

    @media (max-width: map.get($grid-breakpoints, "lg")) {
      font-size: 28px;
    }
  }
}

.captioned-structured-block-grid {
  padding-top: 50px;
  padding-bottom: 50px;

  @media (max-width: map.get($grid-breakpoints, "lg")) {
    padding-top: 0;
    padding-bottom: 50px;
  }

  @media (max-width: map.get($grid-breakpoints, "md")) {
    padding-top: 30px;
    padding-bottom: 50px;
  }

  .row {
    @media (min-width: map.get($grid-breakpoints, "lg")) {
      margin-left: -20px;
      margin-right: -20px;
    }

    @media (max-width: map.get($grid-breakpoints, "lg")) {
      margin-left: 0;
      margin-right: 0;
    }

    @media (max-width: map.get($grid-breakpoints, "md")) {
      margin-left: -5px;
      margin-right: -5px;
    }
  }

  .photo-sb {
    text-align: center;
    padding: 0 5px;
    margin-bottom: 10px;

    @media (max-width: map.get($grid-breakpoints, "lg")) {
      padding: 0 4px;
      margin-bottom: 8px;
    }

    .align {
      background: var(--dark-gray);
      height: 100%;
    }

    .img-wrapper {
      position: relative;
      overflow: hidden;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        display: block;
        z-index: 1;
      }

      .sb-caption-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 2;

        .sb-caption-wrapper-inner {
          display: table;
          width: 100%;
          height: 100%;
          padding: 0 50px;

          .sb-caption {
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            color: var(--white);
            font-size: 22px;
            line-height: 1.5;
            font-family: --font-family-serif, serif;

            a {
              color: var(--white);
            }

            @media (max-width: map.get($grid-breakpoints, "lg")) {
              font-size: 18px;
            }

            @media (max-width: map.get($grid-breakpoints, "md")) {
              font-size: 16px;
            }
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      vertical-align: top;
    }

    .photo-caption {
      color: var(--white);
      padding: 30px;
      background: var(--dark-gray);

      @media (max-width: map.get($grid-breakpoints, "lg")) {
        padding: 22px;
      }

      p {
        font-size: 16px;

        @media (max-width: map.get($grid-breakpoints, "lg")) {
          font-size: 14px;
          line-height: 1.5;
        }
      }
    }
  }
}

.full-width-video {
  position: relative;

  .video-modal-inline {
    position: relative;
  }

  @media (max-width: map.get($grid-breakpoints, "lg")) {
    max-width: 100%;
  }

  &.active {
    .icon-play {
      opacity: 0 !important;
      visibility: hidden;
    }

    .blockquote {
      @media (min-width: map.get($grid-breakpoints, "md")) {
        opacity: 0 !important;
        transition: all 0.2s;
        transform: translateY(30px);
      }
    }

    .video-modal-inline {
      overflow: visible;
    }

    video,
    iframe {
      z-index: 2;
    }

    .icon-stop {
      opacity: 1;
    }

    &.visibility-hidden {
      .icon-play {
        visibility: hidden;
      }

      .head,
      .blockquote {
        @media (min-width: map.get($grid-breakpoints, "md")) {
          visibility: hidden;
        }
      }

      .slide.active .blockquote {
        transition: all 0.2s;
      }
    }
  }

  .icon-play,
  .icon-stop {
    @include vertical();

    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    cursor: pointer;
    transform: translate(-50%, -50%);
    margin: -14px 0 0 2px;

    @include size(35px);

    font-size: 35px;
    transition: opacity 0.2s;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      @include size(55px);

      font-size: 55px;
    }
  }

  .icon-play {
    color: rgba(255, 255, 255, 0.8);
    transition: color 0.25s linear;
    cursor: pointer;

    @media (max-width: map.get($grid-breakpoints, "lg")) {
      margin: 0;
    }
  }

  &:hover {
    .icon-play {
      color: var(--white);
    }
  }

  @include common-slide-video();

  .blockquote {
    background: var(--accent-blue);

    @include font(16, 25, 400);

    position: relative;
    z-index: 3;
    padding: 18px 26px 30px;
    margin: -111px auto 81px auto;
    width: 80%;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      padding: 25px 30px 32px;

      @include fontsize(28, 38);

      transition: opacity 0.6s ease-in 0.4s, transform 0.6s ease-in 0.4s;

      /* opacity: 0; */
      transform: translateY(0);
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      padding: 25px 30px 32px 46px;
      width: 80%;
      opacity: 1;
    }

    @media (max-width: map.get($grid-breakpoints, "lg")) {
      margin: -111px auto 60px auto;
    }

    @media (max-width: map.get($grid-breakpoints, "md")) {
      margin: -1px auto 60px auto;
      width: 100%;
    }

    .btn {
      margin-top: 20px;
    }

    q {
      font-family: --font-family-serif, serif;

      &::before,
      &::after {
        content: none;
      }

      @media (min-width: map.get($grid-breakpoints, "md")) {
        margin: 0 0 20px;
      }

      @media (max-width: map.get($grid-breakpoints, "md")) {
        font-size: 20px;
      }
    }

    p {
      font-size: 18px;
      line-height: 1.5;

      @media (max-width: map.get($grid-breakpoints, "md")) {
        font-size: 14px;
        line-height: 1.5;
      }
    }
  }
}

.no-margin-bottom {
  margin-bottom: 0;

  @media (max-width: map.get($grid-breakpoints, "md")) {
    margin-bottom: 15px;
  }

  &.banner {
    margin-bottom: 0;

    @media (max-width: map.get($grid-breakpoints, "md")) {
      margin-bottom: 15px;
    }
  }
}

.light-gray-block {
  background: var(--gray-bg);
  padding: 20px 15px;

  @media (max-width: map.get($grid-breakpoints, "lg")) {
    padding: 55px 0;
  }

  @media (max-width: map.get($grid-breakpoints, "md")) {
    padding-top: 35px;
    padding-bottom: 10px;
  }

  .header {
    margin-bottom: 20px;

    @media (max-width: map.get($grid-breakpoints, "lg")) {
      margin-bottom: 10px;
    }
  }

  &.events-large {
    padding-top: 50px;
    padding-bottom: 30px;
  }
}

.information-blocks {
  //padding: 1em 10%;
  background: var(--gray-bg); // may change 2021

  .container {
    max-width: 1131px;

    .row {
      margin-left: -18px;
      margin-right: -18px;
      overflow: hidden;

      @media (max-width: map.get($grid-breakpoints, "lg")) {
        margin-left: -17px;
        margin-right: -17px;
      }

      position: relative;
    }
  }
}

.information-block {
  padding: 0 0 2em;
  position: relative;
  text-align: center;
  background-color: var(--white);

  @media (max-width: map.get($grid-breakpoints, "md")) {
    &.no-content {
      display: none;
    }
  }

  .bg-stretch {
    @media (max-width: map.get($grid-breakpoints, "md")) {
      width: 25%;
      padding-bottom: 25%;
      bottom: auto;
    }
  }

  img {
    width: 100%;
    max-width: 100%;
    height: auto !important;
  }

  &.no-content {
    padding: 0;

    .information-block-inner {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      overflow: hidden;
      background: --esc-primary url(../images/icons/empire-shield@2x.png) center/150% no-repeat;
    }
  }

  .information-block-inner {
    @media (max-width: map.get($grid-breakpoints, "lg")) {
      //border: 2px solid --white;
    }

    @media (max-width: map.get($grid-breakpoints, "md")) {
      background: var(--white);
    }

    div {
      padding: 0;
    }
  }

  @media (min-width: map.get($grid-breakpoints, "sm")) {
    &:first-child .information-block-inner div,
    &:nth-child(3) .information-block-inner div,
    &:nth-child(5) .information-block-inner div {
      padding-left: 0;
      padding-right: 5px;
    }

    &:nth-child(2) .information-block-inner div,
    &:nth-child(4) .information-block-inner div,
    &:nth-child(6) .information-block-inner div {
      padding-left: 5px;
      padding-right: 0;
    }
  }

  @media (min-width: map.get($grid-breakpoints, "xxl")) {
    &:first-child .information-block-inner div,
    &:nth-child(4) .information-block-inner div {
      padding-left: 0;
      padding-right: 5px;
    }

    &:nth-child(2) .information-block-inner div,
    &:nth-child(5) .information-block-inner div {
      padding-left: 5px;
      padding-right: 5px;
    }

    &:nth-child(3) .information-block-inner div,
    &:nth-child(6) .information-block-inner div {
      padding-left: 5px;
      padding-right: 0;
    }
  }

  &.double-wide {
    .information-block-inner {
      background-size: 100%;
    }
  }

  .information-block-overlay,
  .information-block-info,
  .information-block-inner .information-block-info {
    background: var(--white);
  }

  .information-block-info {

    //z-index: 3;
    padding-left: 1em;
    padding-right: 1em;
    min-height: 12em;

    a::after {
      display: none;
    }

    @media (min-width: map.get($grid-breakpoints, "md")) {
      min-height: 18em;
    }

    h3,
    .information-block-overlay-title {
      min-height: 2em;
      background-color: var(--white);
      margin-top: 0;
      padding-top: 0.5em;

      @media (min-width: map.get($grid-breakpoints, "md")) {
        min-height: 2.75em;
      }
    }

    p,
    .info-desc {
      min-height: 5em;

      @media (min-width: map.get($grid-breakpoints, "md")) {
        min-height: 10em;
      }
    }
  }
}

.information-block.active-state {
  .information-block-overlay {
    @media (min-width: map.get($grid-breakpoints, "lg")) {
      opacity: 0;
    }
  }

  .information-block-info {
    @media (min-width: map.get($grid-breakpoints, "lg")) {
      opacity: 1;
      visibility: visible;
    }
  }

  .overlay-top {
    display: none;
  }
}

.tablet-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.4);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s;
  z-index: 11;

  .overlay-top {
    display: none;
  }

  &.on {
    @media (max-width: map.get($grid-breakpoints, "lg")) {
      opacity: 1;
      visibility: visible;
    }
  }

  @media (max-width: map.get($grid-breakpoints, "md")) {
    opacity: 0;
    visibility: hidden;
    display: none;
  }

  .tablet-overlay-inner {
    width: 65%;
    height: 0;
    padding-bottom: 65%;
    background: var(--white);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    .information-block {
      width: 100%;
      height: 100%;
      position: absolute;

      .icon-info,
      .information-block-overlay {
        display: none;
      }

      .information-block-overlay-title {
        font-size: 36px;
        line-height: 1.5;
      }

      .information-block-info p.info-desc {
        font-size: 20px;
        line-height: 1.5;
        margin-bottom: 8%;
      }

      .information-block-inner {
        border: 0;
      }

      .btn {
        font-size: 16px;
        max-width: 240px;
        padding: 17px 30px;
      }

      .information-block-info {
        opacity: 1;
        visibility: visible;
        padding: 30px;
      }
    }
  }

  .close {
    width: 36px;
    height: 36px;
    position: absolute;
    top: 20px;
    right: 30px;
    z-index: 10;
    cursor: pointer;

    &::after,
    &::before {
      background: var(--white);
      height: 4px;
      position: absolute;
      display: block;
      content: "";
      top: 50%;
      bottom: auto;
      left: 0;
      right: 0;
    }

    &::after {
      transform: rotate(45deg);
    }

    &::before {
      transform: rotate(-45deg);
    }
  }
}

.four-across-block {
  padding-top: 70px;
  padding-bottom: 35px;

  @media (max-width: map.get($grid-breakpoints, "lg")) {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .header {
    margin-bottom: 50px;
  }
}

.four-across {
  overflow: hidden;
  list-style: none;
  margin-left: -23.5px;
  margin-right: -23.5px;
  padding: 0;
  margin-bottom: 0;

  @media (max-width: map.get($grid-breakpoints, "lg")) {
    margin-left: 0;
    margin-right: 0;
  }

  li {
    width: 25%;
    float: left;
    padding: 0 8.5px;
    line-height: 1.5;

    @media (max-width: map.get($grid-breakpoints, "lg")) {
      width: 50%;
      margin-bottom: 40px;
      min-height: 350px;

      &:nth-child(3),
      &:nth-child(4) {
        margin-bottom: 0;
      }
    }

    @media (max-width: map.get($grid-breakpoints, "md")) {
      width: 100%;
      margin-bottom: 15px;
      padding: 0;

      &:nth-child(3) {
        margin-bottom: 25px;
      }
    }

    img {
      width: 100%;
      margin-bottom: 15px;
    }

    a {
      font-size: 1rem;
      margin-bottom: 5px;
      display: block;
    }

    p {
      font-size: 0.9rem;
      padding-right: 10px;
    }
  }
}

.text-grid {
  padding-top: 75px;
  padding-bottom: 75px;
  display: flex;
  flex-flow: wrap;

  @media (max-width: map.get($grid-breakpoints, "lg")) {
    padding-top: 50px;
    padding-bottom: 60px;
  }

  @media (max-width: map.get($grid-breakpoints, "md")) {
    padding-top: 40px;
  }

  .header {
    margin-bottom: 50px;

    @media (max-width: map.get($grid-breakpoints, "lg")) {
      margin-bottom: 30px;
    }

    @media (max-width: map.get($grid-breakpoints, "md")) {
      margin-bottom: 10px;
      padding: 0;
    }
  }

  @media (max-width: map.get($grid-breakpoints, "md")) {
    .container,
    .header {
      padding: 0;
    }
  }

  .text-grid-list {
    // margin: -3px -18px; jen 2021
    width: auto;
    padding: 0;
  }

  li {
    list-style: none;
    width: calc(50% - 8px);
    margin: 4px;
    float: left;
    padding: 50px 60px;
    background: var(--accent-orange);
    border: 4px solid var(--esc-primary);

    @media (max-width: map.get($grid-breakpoints, "lg")) {
      padding: 35px 40px;
    }

    @media (max-width: map.get($grid-breakpoints, "md")) {
      width: 100%;
      float: none;
      padding: 35px 15px;
    }

    p {
      font-size: 1rem;
      line-height: 1.5;

      @media (max-width: map.get($grid-breakpoints, "lg")) {
        font-size: 14px;
        line-height: 1.5;
      }
    }

    .text-grid-link {
      font-size: 1.7rem;
      line-height: 1.5;
      font-family: --font-family-serif, serif;
      font-weight: 500;
      margin-bottom: 25px;
      display: block;
    }
  }
}

.photo-pair {
  margin-bottom: -60px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 975px;
  padding: 0 15px;

  @media (max-width: map.get($grid-breakpoints, "md")) {
    margin-left: -15px;
    margin-right: -15px;
    padding: 0;
  }

  img {
    width: 100%;
    height: auto;
  }

  &::after {
    content: "";
    display: block;
    clear: both;
  }

  .photo-pair-1 {
    margin: 30px 0 0;
    float: left;
    width: 50%;

    @media (max-width: map.get($grid-breakpoints, "md")) {
      padding-right: 90px;
      width: 100%;
      float: none;
    }

    img {
      margin-right: -20px;

      @media (max-width: map.get($grid-breakpoints, "md")) {
        margin-right: 0;
      }
    }
  }

  .photo-pair-2 {
    float: right;
    position: relative;
    top: 80px;
    width: 50%;

    @media (max-width: map.get($grid-breakpoints, "md")) {
      padding-left: 140px;
      margin-top: -30px;
      top: auto;
      width: 100%;
      float: none;
    }

    img {
      margin-left: -20px;

      @media (max-width: map.get($grid-breakpoints, "md")) {
        margin-left: 0;
      }
    }
  }
}

.email-block-overlay {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in, visibility 0.2s ease-in;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;

  *:focus {
    outline-style: none;
  }

  background: rgba(255, 255, 255, 0.6);

  @media (max-width: map.get($grid-breakpoints, "lg")) {
    border: 40px solid transparent;
  }

  @media (max-width: map.get($grid-breakpoints, "md")) {
    border: 15px solid transparent;
  }

  &.on {
    visibility: visible;
    opacity: 1;
  }
}

.email-block {
  background: var(--white);
  padding: 71px 113px 0;
  max-width: 936px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  width: 100%;
  z-index: 10;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 24px 0 rgba(131, 131, 131, 0.08);

  @media (max-width: map.get($grid-breakpoints, "lg")) {
    padding: 50px 50px 0;
    width: 100%;
  }

  @media (max-width: map.get($grid-breakpoints, "md")) {
    padding: 60px 20px 0;
  }

  form {
    margin: 40px -20px 40px 0;

    @media (max-width: map.get($grid-breakpoints, "md")) {
      margin: 0;
    }
  }

  .email-directly {
    font-size: 16px;

    a {
      margin-left: 40px;
      color: black;
      font-weight: 600;

      @media (max-width: map.get($grid-breakpoints, "md")) {
        display: block;
        margin-left: 0;
      }

      .icon-email {
        background-image: url(../images/2x/email-black@2x.png);
      }
    }
  }

  .required-field {
    color: var(--esc-primary);
    font-weight: 600;
  }

  .close {
    width: 36px;
    height: 36px;
    position: absolute;
    top: 20px;
    right: 30px;
    z-index: 10;
    cursor: pointer;

    @media (max-width: map.get($grid-breakpoints, "md")) {
      right: 20px;
    }

    &::after,
    &::before {
      background: var(--esc-primary);
      height: 4px;
      position: absolute;
      display: block;
      content: "";
      top: 50%;
      bottom: auto;
      left: 0;
      right: 0;
    }

    &::after {
      transform: rotate(45deg);
      margin: 0;
    }

    &::before {
      transform: rotate(-45deg);
    }
  }
}

form {
  .select-input {
    label {
      font-weight: 400;
      font-size: 16px;
      text-align: left;

      @media (max-width: map.get($grid-breakpoints, "lg")) {
        font-size: 14px;
      }
    }
  }

  .text-input,
  .text-area {
    width: 33.333333333%;
    float: left;
    text-align: left;
    font-size: 16px;
    margin-bottom: 10px;

    @media (max-width: map.get($grid-breakpoints, "md")) {
      float: none;
      width: 100%;
    }

    label {
      font-weight: 400;
      padding-right: 20px;

      @media (max-width: map.get($grid-breakpoints, "lg")) {
        font-size: 14px;
      }

      @media (max-width: map.get($grid-breakpoints, "md")) {
        display: block;
        padding-right: 0;
      }
    }

    input,
    textarea {
      border: 1px solid var(--dark-gray);
      box-shadow: inset 0 1px 6px 0 rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      max-width: 239px;
      height: 54px;
      padding: 12px;
      margin: 6px 0;
      font-size: 18px;
      font-weight: 600;
      resize: none;
      width: 100%;

      @media (max-width: map.get($grid-breakpoints, "md")) {
        max-width: 100%;
      }
    }
  }

  .text-area {
    width: 100%;

    label {
      display: block;
    }

    textarea {
      height: 100px;
      width: 100%;
      max-width: 100%;
      resize: none !important;
    }
  }

  .btn {
    border-radius: 4px !important;
    background: var(--primary-blue);
    min-width: 257px;
    margin: 30px auto 40px;

    &:hover {
      background: darken(#6787b7, 5);
    }
  }
}

label {
  font-weight: bold;
  margin-top: 2em;
}

.twitter-block-large {
  background: var(--accent-blue);
  color: var(--white);
  padding: 60px 0 20px;
  margin-bottom: 30px;

  @media (max-width: map.get($grid-breakpoints, "lg")) {
    padding: 50px 0 20px;
  }

  .container {
    @media (max-width: map.get($grid-breakpoints, "lg")) {
      max-width: 100%;
    }
  }

  h3,
  a {
    color: var(--white);
  }

  .btn {
    font-size: 18px;

    .icon {
      margin-right: 10px;
      font-size: 28px;
      line-height: 1.5;
      position: relative;
      top: 6px;
    }
  }

  ul {
    overflow: hidden;
    padding: 30px 0;
    margin: 0 -50px;

    @media (max-width: map.get($grid-breakpoints, "lg")) {
      margin: 0;
    }

    li {
      display: inline-block;
      float: left;
      width: 33.3333333%;
      padding: 0 50px;
      font-size: 16px;
      list-style: none;

      @media (max-width: map.get($grid-breakpoints, "lg")) {
        padding: 0 25px;
        font-size: 14px;
        line-height: 1.5;
      }

      @media (max-width: map.get($grid-breakpoints, "md")) {
        width: 100%;

        &:nth-child(2),
        &:nth-child(3) {
          display: none;
        }

        .date {
          font-size: 16px;
        }
      }

      .date {
        margin-bottom: 20px;
        text-transform: uppercase;
      }

      &:nth-child(4) {
        display: none;
      }
    }
  }
}

.news-large {
  .news-holder {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 -24px;

    @media (max-width: map.get($grid-breakpoints, "lg")) {
      margin: 0 -8px;
    }
  }

  .news-block {
    margin: 0 0 40px;
  }

  .news-item {
    width: 33.33333%;
    font-size: 18px;
    line-height: 1.5;
    padding: 0 24px 35px;
    min-height: 337px;
    margin: 0;

    @media (max-width: map.get($grid-breakpoints, "lg")) {
      padding: 0 8px 35px;
      font-size: 14px;
      min-height: 0;

      p {
        padding-right: 10px;
        line-height: 1.5;
      }
    }

    @media (max-width: map.get($grid-breakpoints, "md")) {
      width: 100%;
      display: block;

      p {
        padding-right: 20px;
      }
    }
  }
}

.events-large {
  margin-bottom: 12px;

  &.light-gray-block {
    @media (max-width: map.get($grid-breakpoints, "lg")) {
      padding-top: 40px;
    }

    @media (max-width: map.get($grid-breakpoints, "md")) {
      padding-bottom: 30px;
    }
  }

  .events-list {
    background: transparent;
    overflow: hidden;
    padding: 0;

    @media (max-width: map.get($grid-breakpoints, "md")) {
      margin-left: 0;
      margin-right: 0;
    }

    li {
      opacity: 1;
      width: 50%;
      float: left;

      @media (max-width: map.get($grid-breakpoints, "md")) {
        width: 100%;
        float: none;
      }
    }

    @media (max-width: map.get($grid-breakpoints, "lg")) {
      .data {
        font-size: 14px;
        padding: 0 50px 0 27px;
      }
    }
  }

  .events-list .time {
    padding: 7px 0;
    width: 20%;

    @media (max-width: map.get($grid-breakpoints, "lg")) {
      width: 25%;
    }
  }

  .events-list .data {
    width: 80%;

    @media (max-width: map.get($grid-breakpoints, "lg")) {
      width: 75%;
    }
  }

  .events-list li::before {
    left: 21%;

    @media (max-width: map.get($grid-breakpoints, "lg")) {
      left: 26%;
    }
  }

  .more {
    opacity: 1;
  }
}

.alt-header-block {
  hr {
    @media (max-width: map.get($grid-breakpoints, "md")) {
      display: none;
    }
  }

  h1 {
    @media (max-width: map.get($grid-breakpoints, "md")) {
      font-weight: 500;
      margin-bottom: 0.5em;
    }
  }
}

.alt-header-banner {
  background: var(--dark-gray) center/cover no-repeat;
  color: var(--white);
  font-size: 28px;
  margin-bottom: 60px;
  margin-top: -14px;
  padding: 20px 30px;
  position: relative;

  &.has-image {
    &::after {
      content: "";
      background-image: linear-gradient(50deg, rgba(156, 154, 154, 0) 0%, var(--gray-bg) 100%);
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      transform: rotate(180deg);
    }
  }

  @media (max-width: map.get($grid-breakpoints, "lg")) {
    margin-top: 0;
    padding: 15px 40px;
  }

  @media (max-width: map.get($grid-breakpoints, "md")) {
    padding: 15px;
    font-size: 18px;
    margin-top: -36px;
    margin-bottom: 40px;
  }

  p {
    margin: 0;
    position: relative;
    z-index: 1;
  }
}

.open-close.moving-box + .open-close.moving-box {
  display: none;
}

/* Non-standard CSS. Not sure intent of this when no url-prefix is supplied.
@-moz-document url-prefix() {
  .alt-header-banner {
    margin-top: -13px;

    @media (max-width: map.get($grid-breakpoints, "lg")) {
      margin-top: 0;
    }

    @media (max-width: map.get($grid-breakpoints, "md")) {
      margin-top: -35px;
    }
  }

  .media-block .flex-row {
    display: block;
  }
}
*/

main .alt-header .open-close {
  @media (min-width: map.get($grid-breakpoints, "lg")) {
    display: none;
  }
}

/*
header .megamenu {
  @media (max-width: map.get($grid-breakpoints, "md")) {
    display: none;
  }
}
*/

.intro-block {
  .open-close {
    display: none;
  }

  .bg-stretch {
    &::after {
      background: #1d428190;
    }
  }
}

.alt-header {
  .intro-block {
    .open-close {
      @media (max-width: map.get($grid-breakpoints, "md")) {
        display: block;
      }
    }
  }

  > .open-close {
    @media (min-width: map.get($grid-breakpoints, "md")) {
      display: none;
    }

    @media (max-width: map.get($grid-breakpoints, "md")) {
      display: block;
      position: relative;
      padding: 0 15px;
      left: auto;
      right: auto;
      margin-bottom: 40px;

      .slide {
        left: 15px !important;
        right: 15px !important;
      }

      &:hover {
        .slide {
          left: 15px !important;
          right: 15px !important;
        }
      }
    }
  }
}

/* Moved location page related code to _esc-locations.scss */

figure {
  margin: 0;
  padding: 30px 26px;
  text-align: center;
  border: none;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 400;

  @media (min-width: map.get($grid-breakpoints, "md")) {
    float: right;
    width: 31%;
    padding: 0 0 33px 20px;
  }

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    font-size: var(--font-size-h1);
    line-height: 1.5;
    padding: 0 0 47px 20px;
  }

  figcaption {
    display: block;
    font-style: italic;
    position: relative;
    color: var(--dark-gray);
    font-size: var(--font-size-figure-caption);
    line-height: 1.5;

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      font-size: var(--font-size-h6);
      line-height: 1.5;
    }
  }

  img {
    display: block;
    margin: 0 auto 12px;
    max-width: 100%;
    height: auto !important;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      margin-bottom: 20px;
    }
  }
}

.figure-large {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.figure-left {
  @media (min-width: map.get($grid-breakpoints, "md")) {
    float: left;
    padding: 0 20px 0 0;
  }

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    padding: 0 20px 0 0;
  }
}

.figure-tall {
  @media (min-width: map.get($grid-breakpoints, "md")) {
    width: 31%;
  }
}

.figure-wide {
  @media (min-width: map.get($grid-breakpoints, "md")) {
    width: 45%;
  }
}

// new submenu

.nav-subsite {
  position: sticky;
  top: 58px;
  //margin-left: -18px;
  padding: 20px 40px;
  background: #ebfaff;
  overflow-y: auto;
  max-height: var(--bs-scroll-height, 75vh);
  z-index: 99;

  ul.nav,
  ul.nav ul {
    list-style-type: none;
    margin-top: 10px;
  }

  &.nav a[data-submenu]::after {
    display: none;
  }

  ul > li > ul > li a {
    text-transform: capitalize !important;
    font-weight: normal !important;
  }

  .nav li,
  .nav li li {
    margin-bottom: 10px;
  }

  .nav {
    border-right: none;

    li li:last-child,
    .nav-subsite .nav li.multilevel-linkul-0:last-child {
      margin-bottom: 0;
    }

    a {
      text-transform: uppercase;
      font-family: var(--font-family-sans-serif-custom);
      font-weight: 500;
      color: var(--primary-blue);
      text-decoration: none;

      &:hover {
        color: #7b3701;
        text-decoration: underline;
      }

      .multilevel-linkul-0 {
        margin-bottom: 5px;

        a {
          text-transform: none;
          font-size: 0.9em;
        }
      }
    }

    a.btn.btn-primary:link,
    a.btn.btn-primary:visited {
      color: var(--white);
    }

    a.btn.btn-primary:hover,
    a.btn.btn-primary:active {
      text-decoration: underline;
      background: var(--primary-blue);
      border-color: var(--primary-blue);
    }
  }

  &[class^=multilevel-linkul-] {
    max-height: initial;
  }

  #menuhead {
    margin-bottom: 0;

    .mainMenu {
      margin-top: 10px;
      margin-bottom: 40px;
    }

    .subsite-menu-label {
      &::after {
        content: "▼";
        font-size: 12px;
        margin-left: 3px;
        padding-top: 2px;
        transition: transform 0.5s;
        transform-origin: center;
        display: inline-block;
        color: var(--secondary-blue);
        text-decoration: none;
      }

      @media (min-width: map.get($grid-breakpoints, "lg")) {
        display: none;
      }
    }

    .collapsed {
      .subsite-menu-label {
        &::after {
          color: var(--esc-primary);
          transition: transform 0.5s;
          transform-origin: center;
          transform: rotate(180deg);
          margin-bottom: -4px;
        }
      }

      @media (min-width: map.get($grid-breakpoints, "lg")) {
        display: none;
      }
    }
  }

  @media (min-width: map.get($grid-breakpoints, "md")) {
    position: static;
    margin: 0;
    padding: 20px 10px;
    overflow-y: visible;
    max-height: none;
    z-index: initial;
  }

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    padding-bottom: 80px;
  }

  &.nav {
    flex-wrap: nowrap;
  }
}

#collapseSubsite {
  @media (min-width: map.get($grid-breakpoints, "lg")) {
    display: block;
  }

  .d-block {
    font-weight: bold;

    @media (max-width: map.get($grid-breakpoints, "lg")) {
      margin-left: 1rem;
    }
  }

  .slide {
    padding-left: 2rem;

    .currentbranch0 {
      font-weight: bolder;
      text-transform: capitalize;
    }

    .currentbranch0,
    .currentbranch1 {
      color: #7b3701;
      font-style: italic;

      a {
        font-style: initial;
      }
    }
  }
}

/* NEW STUFF FROM T4 main {
  margin: unset !important;
} */

#breadcrumb div {
  padding: 0;
}

.nav-breadcrumb {
  background: var(--tertiary-blue);
  border: none;
  margin: 0;
}

.breadcrumb,
.breadcrumb a,
.breadcrumb a:link {
  color: var(--white);
  text-transform: uppercase;
  font-family: var(--font-family-sans-serif-custom);
  text-decoration: none;
  font-weight: normal;
}

.breadcrumb a:hover {
  color: var(--white);
  text-decoration: underline;
}

ul.breadcrumb {
  margin: 0.5rem 0;
}

.breadcrumb > li {
  display: inline-block;
  font-weight: 700;
  padding: 0 6px;
  position: relative;
  vertical-align: top;

  &:last-child {
    color: var(--accent-orange);
  }
}

.nav-breadcrumb .breadcrumb {
  border-bottom: 0;
}

.bg-container {
  .content-block {
    margin-bottom: 0 !important;
  }

  .body-container {
    @media (max-width: map.get($grid-breakpoints, "lg")) {
      max-width: none;

      .col-maincontent,
      .nav {
        max-width: 720px;
        margin: 0 auto;
      }

      .nav {
        padding-left: 30px;
        padding-right: 30px;
      }
    }

    @media (max-width: map.get($grid-breakpoints, "md")) {
      .nav {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.esc-bg-primary-light {
  .content-block {
    > .row {
      margin-left: -30px;
      margin-right: -30px;

      @media (min-width: map.get($grid-breakpoints, "lg")) {
        margin-left: -15px;
        margin-right: -15px;
      }
    }
  }
}

@media (min-width: map.get($grid-breakpoints, "lg")) {
  .bg-container {
    background-image: linear-gradient(to right, #ebfaff 30%, white);
  }
}

.col-maincontent,
.col-related {
  padding-top: 20px;
  padding-bottom: 90px;
  background: var(--white);
}

/* jh edits */

.circle-base {
  margin-top: -135px;
}

#esc-navbar-main {
  /* .sitemenu .sitemenu-panel.show {
    margin-top: 20px;
  } */ // remove jen 2021

  .navbar-nav > li > a > :hover::after {
    transform: none;
    -webkit-transform: none;
    color: #f99b1c;
  }
}

.col-maincontent {
  // margin-top: 130px; remove jen 2021

  padding-left: 30px;
  padding-right: 30px;

  h1 {
    margin-top: 0;
  }
}

.student-life-tabs {
  .nav {
    display: inline-block;
    width: 275px;
    vertical-align: top;
    border: none;

    .nav-item {
      display: block;
      width: 275px;
      height: 50px;
      padding: 0.5rem 1rem;
      margin: 1px auto;

      [aria-selected=true] {
        background: #f99b1c;
        text-decoration: underline;
      }

      a {
        padding: 0;
        display: block;
        color: #000;
        text-decoration: none;
        background: #dcddde;
        text-transform: uppercase;
        line-height: 50px;
        text-align: center;
        font-weight: 700;
        font-size: 14px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border: none;
      }
    }
  }

  #slTabsContent {
    vertical-align: top;
    width: calc(100% - 280px);
    display: inline-block;

    .img-large {
      display: inline-block;
      width: 460px;
      vertical-align: top;

      img {
        max-width: 100% !important;
        height: auto !important;
      }
    }

    .tab-pane {
      div:nth-child(2) {
        display: inline-block;
        width: calc(100% - 470px);
      }
    }
  }
}

/* starting "tri color" here */

.tricolor-cards {
  .tricolor-orange .card-header {
    background: var(--esc-primary);
  }

  .tricolor-blue .card-header {
    background: var(--secondary-blue);
  }

  .tricolor-grey .card-header {
    background: var(--accent-orange);
  }

  .card {
    margin-bottom: 10px;

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      margin-bottom: 0;
    }
  }

  .card-header {
    margin-top: 0;

    a {
      color: var(--black);
      font-weight: 600;
      text-decoration: none;
      position: relative;
      display: inline-flex;
      align-items: center;
      font-size: 18px;

      &::after {
        content: none;
      }
    }

    &:hover {
      text-decoration: underline;
    }

    i {
      font-size: 64px;
      margin-right: 15px;
      display: block;
      float: left;
    }
  }

  .card-body {
    min-height: 230px;
    background: var(--white);
  }
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img-responsive,
.thumbnail > img,
.thumbnail a > img {
  display: block;
  max-width: 100%;
  height: auto !important;
}
