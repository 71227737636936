// Colors

:root {
  /*
  --esc-primary: #1d428a;
  --esc-header-bg: var(--esc-primary);
  --esc-header-bg-trans: #1d428af2; // primary-blue with 95% alpha
  --esc-header-bg-gradiant: #142d5e; // darker
  --esc-secondary: #ff9800;
  --esc-header-border: var(--esc-secondary); primary orange
  --esc-footer-bg: var(--primary-blue);
  --esc-btn-bg-request: #f99b1c; secondary blue
  --esc-btn-bg-attend: #efdbb2;
  --esc-btn-bg-apply: #1ea9e1; */ // old - jen 9/29

  --white: #fff;
  --black: #000;
  --gray-base: #000; // may not need
  --header-text: --white;
  --menu-text: --black;
  --brand-danger: #d4423c; // a11y adjustment
  --esc-primary: #ff9800;
  --primary-blue: #1d428a;
  --primary-blue-light: #ebfaff; //used in 2021 sub-site nav
  --secondary-blue: #1ea9e1;
  --tertiary-blue: #142d5d;
  --primary-orange: var(--esc-primary);
  --secondary-orange: #f2a900;
  --tertiary-orange: #be5400;
  --accent-orange: #efdbb2;
  --accent-blue: #6787b7;
  --dark-gray: #555;
  // --secondary-blue: #02244a; //not 2021 standard; below here, avoid using these
  --button-active-blue: #1ea9e1;
  --menu-button-1: #efdbb2;
  --menu-button-2: #1ea9e1;
  --body-bg: #fff;
  --blockquote-bg: #fce7c2;
  --brown-bg: #221409;
  --gray-bg: #dcddde;
  --gray-border: #ccc;
  --light-gray: #f4f4f4;
  // typography
  --link-color: var(--primary-blue);
  --link-hover-color: darken(#1d428a, 15%); // Bootstrap default. For reference.
  --bg-info: var(--primary-blue-light);
  --bg-light: #ff980012;
  // == Buttons
  --btn-default-color: var(--link-color);
  --btn-default-bg: var(--white);
  --btn-default-border: transparent;
  --btn-primary-color: var(--white);
  --btn-primary-bg: var(--link-color);
  --btn-primary-border: transparent;
}
