// Import required variables
@import '../variables/bootstrap-custom';

$base-max-width: 'none';

// list reset
%listreset {
  margin: 0;
  padding: 0;
  list-style: none;
}

// clearfix
%clearfix {
  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

.clearfix {
  @extend %clearfix;
}

// transition
%transition {
  transition: all 0.3s ease-in-out;
}

// justify nav
%justify {
  text-align: justify;
  font-size: 1px;
  line-height: 0;

  > * {
    display: inline-block;
    vertical-align: top;
    text-align: left;
    font-size: $font-size-base;
    line-height: $line-height-base;
  }

  &::after {
    content: '';
    width: 100%;
    display: inline-block;
    vertical-align: top;
  }
}

/// * Helper class to truncate and add ellipsis to a string too long for it to fit
/// * on a single line.
/// * 1. Prevent content from wrapping, forcing it on a single line.
/// * 2. Add ellipsis at the end of the line.

.ellipsis {
  white-space: nowrap; /* 1 */
  text-overflow: ellipsis; /* 2 */
  overflow: hidden;
}

/// Base path for assets (fonts, images...),
/// should not include trailing slash
/// @access public
/// @type String
$asset-base-path: '../assets' !default;

/// Asset URL builder
/// @access private
/// @param {String} $type - Asset type, matching folder name
/// @param {String} $file - Asset file name, including extension
/// @return {URL} - A `url()` function leading to the asset
@function asset($type, $file) {
  @return url($asset-base-path + '/' + $type + '/' + $file);
}

/// Image asset helper
/// @access public
/// @param {String} $file - Asset file name, including extension
/// @return {URL} - A `url()` function leading to the image
/// @require {function} asset
@function image($file) {
  @return asset('images', $file);
}

/// Font asset helper
/// @access public
/// @param {String} $file - Asset file name, including extension
/// @return {URL} - A `url()` function leading to the font
/// @require {function} asset
@function font($file) {
  @return asset('fonts', $file);
}
