// Project uses Bootstrap
// See variables/_bootstrap-custom.scss for typography overrides of Bootstrap
// See vendors/bootstrap/_variables.scss for Bootstrap original

:root {
  --font-family-serif: adelle, serif;
  --font-family-sans-serif: transat-text, open-sans, sans-serif;
  --font-family-sans-serif-custom: transat-text, var(--font-family-sans-serif);
  --headings-font-family: var(--font-family-sans-serif-custom); // repetitive; phase out with 2021
  --headings-font-family-h1: var(--font-family-sans-serif-custom); // repetitive; phase out with 2021
}
