// Navigation

@use "../variables/bs5-grid-breakpoints" as *;
@use "../variables/colors";
@use "../variables/typography";
@use "../helpers/mixins" as *;
@use "../helpers/helpers" as *;
@use "sass:map";

@include skip-to-content;

/* Default focus states */

a:active,
a:focus,
a:hover {
  outline: none;
  text-decoration: underline;
}

a:focus-visible {
  outline: 5px auto;
  outline-offset: -2px;
}

a:focus,
a:hover {
  color: #001e40;
  text-decoration: underline;
}

/* :focus {
  outline: 1px dotted #9c9a9a !important;
} */

/* Layout adjustment for blocks */

.esc-navbar-top-links {
  width: 100%;
  padding: 0;

  @media (min-width: map.get($grid-breakpoints, "sm")) and (max-width: map.get($grid-breakpoints, "xl")) {
    width: 75%;

    .navbar-nav {
      width: 75%;
    }
  }
}

/* Buttons for top bar and elsewhere */

#esc-navbar-top .esc-nav-btn,
#esc-navbar-main .esc-nav-btn {
  width: 100%;
  max-width: 214px;
  font-size: 0.8rem;
  text-align: left; // above this: remove?
  padding: 0.2rem 0.5rem;
  border-color: transparent;

  &.covid-19 {
    color: var(--white);
    background-color: var(--esc-primary);
    border: 1px solid var(--esc-primary);
  }

  &.request-info {
    background-color: var(--esc-primary);
    color: var(--black);
  }

  &.attend-info {
    background-color: var(--accent-orange);
    color: var(--black);
  }

  &.apply-now {
    background-color: var(--secondary-blue);
    color: var(--black);
  }
}

// Navigation Bars - Top Bar, desktop view

#esc-navbar-top {
  font-family: var(--font-family-sans-serif);
  padding: var(--bs-gutter-x, 0.75rem);
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  text-transform: uppercase;

  .navbar-nav .nav-link,
  &.bg-light .navbar-brand {
    color: var(--black);
    font-weight: normal;

    @media (min-width: map.get($grid-breakpoints, "xl")) {
      font-size: 0.8rem;
    }

    @media (min-width: map.get($grid-breakpoints, "xxl")) {
      font-size: 1rem;
    }

    &:hover {
      color: var(--black);
      text-decoration: underline;
    }
  }

  .navbar-nav .esc-nav-btn:hover {
    color: var(--black);
    text-decoration: underline;
  }

  form {
    width: 15rem;
  }

  &.navbar {
    .navbar-nav {
      .megamenu {
        li {
          padding: 8px 0;
        }

        li:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}

/* Navigation Bars - Responsive toggle & main */

#esc-navbar-responsive-head .container {
  padding: 0;
}

#esc-navbar-main {
  padding: 0;

  @media (min-width: map.get($grid-breakpoints, "xl")) {
    padding: 8px 0;
  }

  #esc-nav-links,
  #esc-nav-search-links {
    width: 100%;

    @media (min-width: map.get($grid-breakpoints, "sm")) and (max-width: map.get($grid-breakpoints, "xl")) {
      width: 50%;
    }
  }

  .container {
    padding: 0;
  }

  .dropdown-menu {
    padding: 0;
    border: none;
    width: 100%;
    font-family: var(--font-family-sans-serif);
    border-radius: unset;
    // background: #1d428af2;

    &.show {
      border-left: 3px solid var(--esc-primary);

      @media (min-width: map.get($grid-breakpoints, "xl")) {
        border-left: none;
      }

      .clearfix-wrap,
      .title {
        opacity: 1;
        transition-delay: 0ms;
      }
    }
  }

  .sitemenu {
    @media (min-width: map.get($grid-breakpoints, "xl")) {
      .sitemenu-panel.show {
        max-height: 600px;
        opacity: 0.95;
        top: 3.5em;
        transition: opacity 0.25s ease, max-height 0.5s ease, visibility 0s linear 0s, top 0s linear 0s;
        visibility: visible;
        z-index: 1001;
        background: none;
      }
    }

    .nav-link {
      font-weight: normal;
      font-size: 1.5rem;

      @media (min-width: map.get($grid-breakpoints, "xl")) {
        padding: 20px 5px 0 20px;
        font-size: 1rem;
      }
    }
  }

  &.navbar-dark .navbar-nav .nav-link,
  .dropdown-menu a {
    color: var(--white);
  }

  &.navbar {
    .navbar-nav {
      .megamenu {
        padding-top: 0.25rem;

        li {
          padding: 8px 0;
        }

        li:last-child {
          padding-bottom: 0;
        }
      }
    }
  }

  .dropdown-menu ul {
    list-style: none;
  }

  .esc-submenu-search {
    width: 100%;
    padding: 0;

    @media (min-width: map.get($grid-breakpoints, "sm")) and (max-width: map.get($grid-breakpoints, "xl")) {
      width: 75%;

      .navbar-nav {
        width: 75%;
      }
    }

    li {
      padding: 3px 0;
    }

    &.d-sm-none li {
      margin-left: 5px;
      margin-right: 5px;
      padding: 3px 0;
    }

    .form-control {
      font-size: 0.8rem;
      border-color: var(--white);
      border-right-width: 0;
    }

    .input-group {
      max-width: 214px;
    }

    .input-group-text {
      background-color: var(--white);
    }
  }

  .navbar-nav {
    a:hover,
    a:active,
    a.show {
      text-decoration: underline;
      text-decoration-color: var(--esc-primary);
      background: none;
    }

    & > li > a {
      @media (min-width: map.get($grid-breakpoints, "xl")) {
        color: var(--white);
        margin-top: -15px;
        padding-top: 24px;
      }

      &:hover,
      &:active,
      &:focus {
        color: var(--white);
        text-decoration: underline;
        text-decoration-color: var(--esc-primary);
      }

      &::after {
        text-decoration: none;
        display: inline-block;
        transition: transform 0.5s;
        transform-origin: center;
      }

      //&:hover::after,
      &:active::after,
      &.show::after {
        display: inline-block;
        color: var(--secondary-blue);
        text-decoration: none;
        // transition: transform 1s; remove 2021
        // transition: transform 0.5s, margin 0.5s;  remove 2021
        transform: rotate(180deg);
        margin-bottom: -4px;
      }
    }
  }

  .ac-slide {
    .slide-holder {
      background: rgba(29, 66, 138, 0.95);
    }

    .title {
      display: none;

      @media (min-width: map.get($grid-breakpoints, "xl")) {
        display: block;
        color: var(--white);
        font-size: 36px;
        font-weight: 400;
        line-height: 38px;
        margin: 0 0 21px;
        opacity: 0;
        transition: all 0.3s;
        text-decoration: underline;
        text-decoration-color: var(--secondary-blue);

        &:hover,
        &:focus {
          color: var(--white);
          text-decoration: underline;
          text-decoration-color: var(--esc-primary);
        }
      }

      ul {
        li {
          ul {
            margin: 8px 0 0 0;
          }
        }
      }
    }

    /* @media (min-width: map.get($grid-breakpoints, "xl")) {
      overflow: visible !important;
      margin-top: 20px;

      .slide-holder {
        background: none;
        width: 85vw;
        position: relative;
        left: calc(-1 * (100vw - 100%) / 2);
      }

      .slide-holder::before {
        background: rgba(29, 66, 138, 0.95);
        border-top: 0;
      }
    } */

    @media (min-width: map.get($grid-breakpoints, "xl")) {
      font-size: 16px;
      font-weight: 400;
      height: auto !important;
      line-height: 20px;
      opacity: 0;
      // overflow: visible !important;
      overflow-y: auto !important;
      overflow-x: visible !important;
      padding: 0 30px;
      transition: all 0.3s;
      visibility: hidden;
      z-index: 1;
      margin-top: 20px; // from above

      .clearfix-wrap {
        align-items: flex-start;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        margin: 0 -10px;
        opacity: 0;
        transition: opacity 0.35s ease-out 0.2s;
      }

      ul {
        font-size: 20px;
        line-height: 26px;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          margin: 0 0 14px;

          a {
            color: var(--white);
            font-weight: 400;
            transition: color 0.25s linear;

            &:hover,
            &:focus {
              color: var(--white);
              text-decoration: underline;
              text-decoration-color: var(--esc-primary);
            }
          }
        }
      }

      .col {
        padding: 0 10px;

        /* width: 36.3%;

        &:first-child {
          width: 33.7%;
        }

        &:nth-child(2) {
          width: 30%;
        } */
      }

      .featured-col {
        a {
          display: block;
          border: 2px solid rgba(154, 155, 154, 0.6);
          border-radius: 5px;
          color: #fff;
          font-weight: 400;
          padding: 30px 25px 22px;
          position: relative;
          text-align: center;
        }

        a:focus,
        a:hover {
          span {
            color: var(--white);
            text-decoration: underline;
            text-decoration-color: var(--esc-primary);
          }
        }

        span.icon-star.icon {
          display: block;
          position: relative;
          top: auto;
          left: auto;
          font-size: 34px;
          margin: 0 0 22px;
        }

        span.link-title {
          display: block;
          margin: 0 0 19px;
          font-size: 20px;
          line-height: 24px;
        }
      }

      .slide-holder {
        min-height: 381px;
        position: relative;
        left: calc(-1 * (100vw - 100%) / 2);
        background: none; // from above
        padding: 30px 75px;
        width: 100vw;

        &::before {
          background: #1d428a;
          border-top: 0;
          bottom: 0;
          content: "";
          display: block;
          left: -9999px;
          position: absolute;
          right: -9999px;
          top: -1px;
          z-index: -1;
          // background: rgba(29, 66, 138, 0.95); from above
        }
      }
    }

    @media (min-width: map.get($grid-breakpoints, "xxl")) {
      .slide-holder {
        padding: 30px 150px;
        width: 90vw;
      }
    }

    ul li ul {
      margin: 14px 0 0 14px;

      li:last-child {
        margin-bottom: 0;
      }
    }
  }
}

/* Megamenu related items */

#esc-navbar-main.navbar,
#esc-navbar-top.navbar {
  .navbar-nav {
    padding-top: 10px;

    @media (min-width: map.get($grid-breakpoints, "xl")) {
      padding-top: 0;
    }

    & > li > a::after {
      @media (max-width: map.get($grid-breakpoints, "xl")) {
        position: absolute;
        right: 10px;
      }
    }

    body.feature-navbar-icon & > li:not(.no-icon) > a::after {
      content: '\25BC';
      color: var(--esc-primary);
      font-size: 12px;
      margin-left: 3px;
      padding-top: 2px;
    }

    & > li:not(.no-icon) > a::after {
      @media (min-width: map.get($grid-breakpoints, "xl")) {
        content: '\25BC';
        color: var(--esc-primary);
        font-size: 12px;
        margin-left: 3px;
        padding-top: 2px;
      }
    }

    .megamenu {
      padding: 1rem;
      background: var(--primary-blue);

      @media (min-width: map.get($grid-breakpoints, "xl")) {
        left: 0;
        right: 0;
        width: 100%;

        /* margin-top: 0; */
      }

      li {
        padding: 3px 0;

        &:first-child {
          padding-top: 0;
        }
      }
    }
  }

  &.fixed-top .navbar-collapse,
  &.sticky-top .navbar-collapse {
    @media (max-width: map.get($grid-breakpoints, "xl")) {
      overflow-y: auto;
      max-height: 90vh;
      margin-top: 10px;
    }
  }

  .has-megamenu {
    border-top: var(--white) 1px solid;

    @media (min-width: map.get($grid-breakpoints, "xl")) {
      border-top: none;
      position: static !important;
    }

    > a {
      padding: 8px 0 4px 24px;
    }
  }
}

#header {
  font-family: 'transat-text', sans-serif;
  background: var(--primary-blue);

  .row {
    padding: initial;

    &.top-panel {
      border-bottom: 0;
      background: unset !important;
    }

    &.nav-holder {
      @media (min-width: map.get($grid-breakpoints, "lg")) {
        padding: 15px 15px 0;
      }
    }
  }

  .top-panel {
    @media (min-width: map.get($grid-breakpoints, "lg")) {
      margin-top: 0;
    }
  }
}

#nav > ul > li {
  width: auto;
  margin: auto;

  @media (min-width: map.get($grid-breakpoints, “xxl”)) {
    width: 16.66%;
  }

  &.special {
    background: var(--esc-primary);
    border: 0;

    a {
      color: var(--black);
    }
  }

  & > a {
    @media (min-width: map.get($grid-breakpoints, "lg")) {
      color: var(--white);
      margin-top: -15px;
      padding-top: 31px;

      &:hover,
      &:active,
      &.open {
        text-decoration: none;
        background: rgb(29, 66, 138);
        background: linear-gradient(0deg, rgba(29, 66, 138, 1) 0%, rgba(20, 45, 94, 1) 100%);

        &::after {
          color: var(--secondary-blue);
          text-decoration: none;
          transition: transform 0.5s, margin 0.5s;
          transform: rotate(-180deg);
          margin-bottom: -4px;
        }
      }

      &:focus {
        @media (min-width: map.get($grid-breakpoints, "lg")) {
          background: rgba(29, 66, 138, 0.95);
        }
      }

      &::after {
        content: '\25BC';
        color: var(--esc-primary);
        font-size: 12px;
        margin-left: 3px;
        padding-top: 2px;
      }
    }

    @media (min-width: map.get($grid-breakpoints, “lg”)) {
      padding-left: 10px;
      padding-right: 10px;
    }

    @media (min-width: map.get($grid-breakpoints, “xl”)) {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.add-nav2 a,
.add-nav a {
  color: var(--white);
  font-weight: bold;

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    color: var(--black);
  }
}

.cta-header {
  @media (min-width: map.get($grid-breakpoints, "lg")) {
    padding-right: 64px;
  }

  a {
    color: var(--white);
  }

  .search-opener .icon-cross::after,
  .search-opener .icon-cross::before {
    background: var(--white);
  }
}

#nav > ul > li > a:hover,
#nav > ul > li > a:active,
#nav > ul > li > a:focus,
#esc-navbar-main .ac-slide .title:hover,
#esc-navbar-main .ac-slide .title:focus,
#esc-navbar-main .ac-slide ul a:hover,
#esc-navbar-main .ac-slide ul a:focus,
#esc-navbar-main .ac-slide .aid-list a:hover span,
#esc-navbar-main .ac-slide .aid-list a:focus span,
#esc-navbar-main .ac-slide .featured-col a:focus span,
#esc-navbar-main .ac-slide .featured-col a:hover span,
#esc-navbar-main .ac-slide a:hover {
  color: var(--white);
  text-decoration: underline;
  text-decoration-color: var(--esc-primary);
}

#nav > ul > li > a:hover,
#nav > ul > li > a:active,
#nav > ul > li > a:focus {
  @media (min-width: map.get($grid-breakpoints, "lg")) {
    text-decoration: none;
  }
}

#esc-navbar-main .ac-slide .aid-list a:focus,
#esc-navbar-main .ac-slide .aid-list a:hover,
#esc-navbar-main .ac-slide .featured-col a:focus,
#esc-navbar-main .ac-slide .featured-col a:hover {
  color: var(--white);
  border-color: var(--esc-primary);
  background: none;
  text-decoration: none;
}

#esc-navbar-main .ac-slide .featured-col a:hover span.icon {
  text-decoration: none;
}

.header-inner {
  .col-xs-2 {
    @media (min-width: map.get($grid-breakpoints, "md")), (max-width: map.get($grid-breakpoints, "xl")) {
      // @include media('>=tablet-md', '<=widescreen') {
      max-width: 20%;
      width: auto;
    }
  }

  .col-xs-10 {
    @media (min-width: map.get($grid-breakpoints, "md")), (max-width: map.get($grid-breakpoints, "xl")) {
      // @include media('>=tablet-md', '<=widescreen') {
      width: auto;
      min-width: 80%;
    }
  }
}

.info-links a {
  color: var(--esc-primary);
}

.logo,
.logo img {
  height: auto !important;
  width: auto !important;
  position: unset !important;
  top: 0 !important;
  left: 0 !important;
  box-shadow: none;
  background-color: transparent;
}

.logo img {
  @media (min-width: map.get($grid-breakpoints, "lg")) {
    padding: 0;
  }
}

.nav-active .btn-close {
  background: transparent;
}

.nav-holder {
  @media (min-width: map.get($grid-breakpoints, "lg")) {
    background: rgba(29, 66, 138, 0.95);
    width: initial;
  }
}

.nav-opener {
  &::after,
  &::before,
  span {
    background: var(--white);
  }

  @media (max-width: map.get($grid-breakpoints, "sm")) {
    top: 20px;
  }

  @media (min-width: map.get($grid-breakpoints, "lg")) {
    margin-right: 15px;
  }
}

.search-panel {
  @media (min-width: map.get($grid-breakpoints, "lg")) {
    margin-top: 5px;
  }
}

.search-form {
  .search-close.btn-close {
    margin: 0;
    color: var(--white);

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      color: var(--black);
    }
  }
}

.search-drop {
  @media (min-width: map.get($grid-breakpoints, "xl")) {
    top: 32px;
    right: 0;
  }
}

.top-bar {
  @media (min-width: map.get($grid-breakpoints, "lg")) {
    background: var(--primary-blue-light);
    position: unset;
    width: initial;
  }

  .add-nav {
    padding-right: 25px;

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      background: var(--primary-blue-light);
      position: unset;
      width: 100%;
    }

    .attend-info a {
      background-color: var(--menu-button-1);
    }

    .apply-now a {
      background-color: var(--menu-button-2);
    }
  }
}

/* FOOTER */

footer {
  text-align: center;

  .col-sm-6:first-of-type {
    text-align: right;
  }

  .col-sm-6:nth-of-type(2) {
    text-align: left;
  }

  .footer-div {
    background: var(--primary-blue);

    .row:first-of-type {
      padding-top: 170px;
    }
  }

  .footer-social-icon {
    padding-right: 25px;
  }

  .footer-spacer {
    white-space: pre-wrap;
  }

  .row {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.circle-base {
  border-radius: 50%;
  width: 135px;
  height: 135px;
  background-color: var(--primary-blue);
  margin-right: auto;
  margin-left: auto;
  transform: translateY(67px);
  margin-top: -67px;
}

.os-torch-icon {
  display: block;
  transform: translate(-83px, 24px);
  text-align: left;
}

.footer-2column {
  @media (min-width: map.get($grid-breakpoints, "md")) {
    column-count: 2;
    float: none;
    max-width: 400px;
    margin: 0 auto;
  }
}

/* covid banner */

.covid-banner {
  background-color: var(--esc-primary);
  color: var(--black);
  border: 0;
  margin-bottom: 0;
  padding: 5px;
  border-radius: 0;
  text-align: center;

  a {
    color: var(--black);
    text-decoration: underline;
  }

  &.alert-dismissable .close,
  &.alert-dismissible .close {
    top: 0;
    right: 0;
    position: unset;
    float: none;
    opacity: 1;
    text-shadow: none;
    background: var(--white);
    border-radius: 10px;
    width: 20px;
  }
}

.navbar-light {
  background-color: var(--bg-light);
}
