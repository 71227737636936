@use '../helpers/mixins';

@font-face {
  // @include fontface(icomoon, icomoon, normal, normal);

  font-family: icomoon;
  src: url(/fonts/icomoon.eot);
  src: url(/fonts/icomoon.eot#iefix) format("embedded-opentype"), url(/fonts/icomoon.woff) format("woff"), url(/fonts/icomoon.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon', sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dollar::before {
  content: "\e912";
}

.icon-search::before {
  content: "\e911";
}

.icon-apply::before {
  content: "\e900";
}

.icon-arrow::before {
  content: "\e901";
}

.icon-credit::before {
  content: "\e902";
}

.icon-events::before {
  content: "\e903";
}

.icon-facebook::before {
  content: "\e904";
}

.icon-financial-aid::before {
  content: "\e905";
}

.icon-hat::before {
  content: "\e906";
}

.icon-instagram::before {
  content: "\e907";
}

.icon-linkedin::before {
  content: "\e908";
}

.icon-location::before {
  content: "\e909";
}

.icon-news::before {
  content: "\e90a";
}

.icon-play::before {
  content: "\e90b";
}

.icon-polygon::before {
  content: "\e90c";
}

.icon-star::before {
  content: "\e90d";
}

.icon-time::before {
  content: "\e90e";
}

.icon-twitter::before {
  content: "\e90f";
}

.icon-youtube::before {
  content: "\e910";
}
