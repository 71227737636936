@use "../variables/bs5-grid-breakpoints" as *;
@use "../variables/colors";
@use "../variables/typography";
@use "../helpers/mixins" as *;
@use "../helpers/helpers" as *;
@use "sass:map";

/* Message Banner based on Emergency Banner (_common.scss)  */

.message-banner {
  background-color: var(--primary-blue-light);
  overflow: hidden;
  padding-top: 15px;
  padding-bottom: 15px;

  @media (min-width: map.get($grid-breakpoints, "md")) {
    padding-top: 28px;
    padding-bottom: 28px;
  }

  .row {
    padding: 0;

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      padding: 0 15px;
    }
  }

  .message-banner-inner {
    display: block;
    width: 100%;
    padding-left: 10px;
    margin: 0 auto;
    position: relative;

    &::before {
      width: 28px;
      height: 24px;
      margin-top: 0;
      top: 4px;
      left: 15px;
      position: absolute;

      @media (min-width: map.get($grid-breakpoints, "lg")) {
        left: 25px;
      }

      @media (min-width: map.get($grid-breakpoints, "md")) {
        width: 53px;
        height: 46px;
        margin-top: -23px;
        top: 50%;
      }
    }

    @media (min-width: map.get($grid-breakpoints, "md")) {
      padding-left: 50px;
      display: table;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      padding-left: 50px;
      max-width: 1600px;
    }

    .text,
    .btn-wrapper {
      display: table-cell;
      vertical-align: middle;

      @media (max-width: map.get($grid-breakpoints, "md")) {
        display: block;
      }
    }
  }

  p {
    font-weight: 700;
    font-size: 0.9rem;
    line-height: 1.3;

    &:last-child {
      margin: 0 0 11.5px;
    }

    @media (min-width: map.get($grid-breakpoints, "md")) {
      font-size: 1.1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      font-size: 1.2rem;
    }
  }

  .btn {
    color: black;
    border: 3px solid black;
    font-size: 0.8rem;
    background: transparent;
    padding: 8px 15px;
    float: none;
    margin-left: 0;
    margin-top: 5px;
    margin-bottom: 5px;

    @media (min-width: map.get($grid-breakpoints, "md")) {
      float: right;
      margin-right: 50px;
      margin-top: inherit;
      margin-bottom: inherit;
    }
  }

  .btn-primary:active:hover,
  .btn-primary:active:focus,
  .btn-primary:active.focus,
  .btn-primary.active:hover,
  .btn-primary.active:focus,
  .btn-primary.active.focus,
  .open > .btn-primary.dropdown-toggle:hover,
  .open > .btn-primary.dropdown-toggle:focus,
  .open > .btn-primary.dropdown-toggle.focus {
    color: black;
    background: var(--body-bg);
    border-color: black;
  }

  .glyphicon {
    font-size: 200%;
  }
}

.alert a {
  color: var(--tertiary-blue);
}
