@import '../variables/bootstrap-custom';

/*** Fixes for date-picker ***/

#ui-datepicker-div {
  background: $white;
  padding: 5px;
  border: 1px solid $gray-base;
}

#ui-datepicker-div td {
  padding: 5px;
  text-align: center;
}

a.ui-datepicker-next.ui-corner-all {
  float: right;
}

.ui-datepicker-title {
  text-align: center;
}

.ui-datepicker-calendar th span {
  display: block;
  text-align: center;
}

#esc_start_date,
#esc_end_date {
  width: 150px;
  padding: 0 5px;
  border: 1px solid $clr9;
  border-radius: 4px;
  font-size: small;
}

.form-horizontal .control-label {
  padding-top: 0;
}
